<template>
  <div class="left-container" >
      <ContainerHeader/>
      <ContainerSubHeader/>
      <ContainerContent/>
  </div>
</template>

<script>
import ContainerHeader from './containerHeader/ContainerHeader';
import ContainerSubHeader from './containerSubHeader/ContainerSubHeader';
import ContainerContent from './containerContent/ContainerContent';

export default {
  name: 'leftContainer',
  components:{ContainerHeader,ContainerSubHeader,ContainerContent}
}
</script>

<style src="./LeftContainer.css" scoped></style>