import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/store'
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VCalendar from 'v-calendar';
import moment from 'moment-timezone';
import VueBarcode from 'vue-barcode';

Vue.component('VueSlider', VueSlider)
Vue.component('v-select', vSelect);
Vue.component('VueBarcode', VueBarcode);
Vue.use(PerfectScrollbar);
Vue.use(VCalendar);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

moment.tz.setDefault('Europe/Rome');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
