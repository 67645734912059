<template>
  <div class="login">
    <div class="logo"></div>
    <b-form-input
      class="username"
      :value="store"
      placeholder="Mail"
      @keyup.native="(evt)=>onInput('store',evt)"> 
    </b-form-input>
    <b-form-input
      class="password"
      type="password"
      :value="password"
      placeholder="Password"
      @keyup.native="(evt)=>onInput('password',evt)"> 
    </b-form-input>
    <b-button class="accedi" :disabled="password.length<=0 || isLoadingLogin" v-on:click="login"> 
      <span v-if="!isLoadingLogin">Accedi </span>
      <span v-else class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'login',
  data() {
    return {
      store:"",
      password:"",
      isLoadingLogin:false
    }
  },
  computed:{
    ...mapGetters(['getUrlLogin','getUser','getUrlCheck','getUrlPriceRules']),
  },methods:{
    ...mapActions(['setNavigation','setUser','checkUser','deleteUser','setRightNavigation','setCartRules']),
    onInput(type,evt){
      if(evt.keyCode===13)
        this.login();
      else {
        if(type=="password") this.password=evt.currentTarget.value
        else this.store=evt.currentTarget.value
      }
    },
    login(){
      var loginUrlWithParameters=this.getUrlLogin.replace(Static.EMAIL,this.store).replace(Static.PASSWORD,this.password);
      this.isLoadingLogin=true;
      axios.get(loginUrlWithParameters)
      .then(response => {
        var responseData={...response.data};
        if(responseData.hash){
          responseData.token=responseData.hash;
          delete responseData.hash;
          this.setUser({
            ...{mail:this.store},
            ...responseData
          })
          .then( ()=>{
            var priceRulesUrlWithParameters=this.getUrlPriceRules.replace(Static.EMAIL,this.store).replace(Static.TOKEN,this.getUser.token);
            axios.get(priceRulesUrlWithParameters)
            .then(response => {
              this.setCartRules(response.data);
              this.setNavigation(Static.SEARCH);
              this.setRightNavigation(Static.CART)
            })
            .finally(()=>this.isLoadingLogin=false)
          })
        } else
          this.$bvToast.toast(responseData.message, {
            title: '',
            autoHideDelay: 5000,
            solid:true,
            variant:'danger'
          })
      })
      .catch(() => {
        this.isLoadingLogin=false
        this.$bvToast.toast('Negozio o password non corretti.', {
          title: '',
          autoHideDelay: 5000,
          solid:true,
          variant:'danger'
        })
      })
    }
  },mounted(){
    this.checkUser()
    .then(()=>{
      if(this.getUser && this.getUser.length!=0){
        var checkUrlWithParameters=this.getUrlCheck.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
        this.isLoadingLogin=true;
        axios.get(checkUrlWithParameters)
        .then(()=>{ 
          var priceRulesUrlWithParameters=this.getUrlPriceRules.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
          axios.get(priceRulesUrlWithParameters)
          .then(response => {
            this.setCartRules(response.data);
            this.setNavigation(Static.SEARCH);
            this.setRightNavigation(Static.CART)
          })
          .finally(()=>this.isLoadingLogin=false)
        })
        .catch(() => {
          this.isLoadingLogin=false
          this.deleteUser(this.getUser);
          this.$bvToast.toast('Rifare login.', {
            title: '',
            autoHideDelay: 5000,
            solid:true,
            variant:'danger'
          })
        })
      }
    })
  }
}
</script>

<style src="./Login.css" scoped></style>