<template>
  <div class="right-container" :class="getRightNavigation">
      <ContainerHeader v-if="getRightNavigation!=$options.CALENDAR"/>
      <ContainerSubHeader v-if="isVisibileSubHeader"/>
      <ContainerContentRight :key="'content_right_'+getRightContainerContentComponentKey"/>
      <ContainerFooter :key="'footer_right_'+getRightContainerFooterComponentKey" v-if="!isNotVisibileFooter"/>
      <HoldCarts v-if="isVisibileHoldCarts"/>
  </div>
</template>

<script>
import ContainerHeader from './containerHeader/ContainerHeader';
import ContainerSubHeader from './containerSubHeader/ContainerSubHeader';
import ContainerContentRight from './containerContentRight/ContainerContentRight';
import ContainerFooter from './containerFooter/ContainerFooter';
import HoldCarts from '@/components/holdCarts/HoldCarts';
import { mapGetters } from 'vuex'
import Static from '@/utils/Static';

export default {
  name: 'rightContainer',
  CALENDAR:Static.CALENDAR,
  components:{ContainerHeader,ContainerSubHeader,ContainerContentRight,ContainerFooter,HoldCarts},
  computed:{
    ...mapGetters(['getCart','getRightNavigation','getHoldCarts','getRightContainerContentComponentKey','getRightContainerFooterComponentKey']),
    isVisibileSubHeader(){ return this.getRightNavigation==Static.FILTER || this.getRightNavigation==Static.CART },
    isNotVisibileFooter(){ return (this.getCart.length==0 && this.getRightNavigation==Static.CART) 
      || this.getRightNavigation==Static.FILTER || this.getRightNavigation==Static.ORDERS_LABEL
    },
    isVisibileHoldCarts(){ return this.getHoldCarts.length>0 && this.getRightNavigation==Static.CART }
  }
}
</script>

<style src="./RightContainer.css" scoped></style>