<template>
  <div class="containerSubHeader" :class="getRightNavigation">
    <div class="container" v-if="getRightNavigation==$options.CART">
      <div class="cliente" v-on:click="toCustomers" v-if="getSelectedCustomer==null"> 
        <span class="name"> Aggiungi Cliente </span> 
      </div>
      <div class="cliente selected" v-on:click="setRightNavigation($options.SHIPPING_PLACE)" v-else > 
        <span class="name"> {{getSelectedCustomer.firstname + ' '+ getSelectedCustomer.lastname}} </span>
        <span class="guest" v-if="getRegistrationType==$options.REGISTRATION_TYPE_GUEST">- Ospite</span>
      </div>
      <div class="drop-customer" v-on:click="dropCustomer" v-if="getSelectedCustomer!=null"> </div>
      <div class="nota" @click="setRightNavigation($options.NOTE)"></div>
    </div>
    <div class="container" v-else>
      <div class="rimuovi" :class="{active:getActiveFilters.length>0}" @click="clearActiveFilters"> Rimuovi tutti i filtri </div>
      <div class="delete" :class="{active:getActiveFilters.length>0}" @click="clearActiveFilters"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'containerSubHeader',
  CART:Static.CART,
  SHIPPING_PLACE:Static.SHIPPING_PLACE,
  NOTE:Static.NOTE,
  REGISTRATION_TYPE_GUEST:Static.REGISTRATION_TYPE_GUEST,
  computed:{
    ...mapGetters(['getRightNavigation','getActiveFilters','getUrlCustomers','getUser','getSelectedCustomer','getSearchUrl','getRegistrationType'])
  },methods:{
    ...mapActions(['setRightNavigation','setActiveFilters','setCustomersList','setIsLoadingCustomers','setDataFromResponse','setCustomersPage'
    ,'setNavigation','deleteUser','setSelectedCustomer']),
    clearActiveFilters(){
      this.setActiveFilters([]);
      axios.get(this.getSearchUrl)
        .then(response => { this.setDataFromResponse(response.data) } )
        .catch(() => { } );
    },
    toCustomers(){
      var customersUrlWithParameters=this.getUrlCustomers
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace(Static.FROMDATE,'')
        .replace(Static.TODATE,'')
        .replace(Static.SIZE,'50')
        .replace(Static.IDNEGOZIO,this.getUser.id)
        .replace(Static.PAGE,'1');
      this.setIsLoadingCustomers(true);
      axios.get(customersUrlWithParameters)
      .then((response)=>{
        this.setCustomersPage(2);
        this.setCustomersList(response.data);
      })
      .catch(err=>{if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
      }})
      .finally(()=>this.setIsLoadingCustomers(false))
      this.setRightNavigation(Static.CUSTOMERS_LIST);
    },
    dropCustomer(){ this.setSelectedCustomer(null); }
  }
}
</script>

<style src="./ContainerSubHeader.css" scoped></style>