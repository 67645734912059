<template>
  <div class="productCard">
    <div class="card" @click="productClick" :class="{isInCart:cartIndex!=-1}">
      <div class="counter" v-if="cartIndex!=-1"> {{ cartIndex!=-1?getCart[cartIndex].cartQuantity:0 }}</div>
      <img class="img" :src="Array.isArray(product.data.image)?product.data.image[0]:product.data.image"/>
      <div class="name" @click.stop=""> {{product.data.name}} </div>
      <div class="sku" @click.stop=""> SKU: <span> {{product.data.sku}} </span> </div>
      <div class="price_container">
        <div class="price" v-if="product.pricing['1']"> {{isNotZeroOrNull(product.pricing['1']['0.0000']['EUR'].selling_price)?'€ '+product.pricing['1']['0.0000']['EUR'].selling_price:'€ '+product.pricing['1']['0.0000']['EUR'].listing_price}} </div>
        <div class="special_price" v-if="product.pricing['1'] && isNotZeroOrNull(product.pricing['1']['0.0000']['EUR'].selling_price)"> {{'€ '+product.pricing['1']['0.0000']['EUR'].listing_price}} </div>
      </div>
      <div class="footer">
        <div class="availability-container" v-if="product.header.type==='simple'">
          <div class="availability"> Disponibili: {{product.warehouses[0].quantity}}</div>
          <div class="availability-detail"> M9: {{product.data.invent_m9}} - Neg: {{product.data.invent_negozi}}</div>
        </div>
        <div class="configurabile-label" v-else-if="product.header.type==='configurable'">
          Prodotto Configurabile
        </div>
        <div class="configurabile-label" v-else-if="product.header.type==='bundle'">
          Prodotto Bundle
        </div>
        <!-- <div class="product-type"> Prodotto configurabile </div> -->
        <div class="info"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Static from '@/utils/Static';

export default {
  name: 'ProductCard',
  props: ['product'],
  computed:{
    ...mapGetters(['getCart']),
    cartIndex(){ return Static.checkSku(this.getCart,this.product.data) }
  },methods:{
    ...mapActions(['setShowProductModal','setSelectedProduct','setShowProductModalWithDesc','setShowProductModalFromCart']),
    productClick(){
      this.setSelectedProduct(this.product);
      this.setShowProductModalWithDesc(true);
      this.setShowProductModalFromCart(false);
      this.setShowProductModal(true);
    },
    isNotZeroOrNull(value){ return Static.isNotZeroOrNull(value); }
  }
}
</script>

<style src="./ProductCard.css" scoped/>
