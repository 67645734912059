<template>
  <div class="containerSubHeader" :class="{disappear:!startSearching}" v-if="getNavigation==$options.SEARCH">
    <div class="results-number"> {{getResultsNumber}} - Risultati di ricerca </div>
    <b-dropdown>
      <template v-slot:button-content>
        <span class="icon"></span>
        <span>{{ getOrder.label }}</span>
      </template>
      <b-dropdown-item v-for="(item,index) in $options.ORDERS_LIST" :key="'order_value_'+index" @click="setOrder(item)">{{item.label}}</b-dropdown-item>
    </b-dropdown>
  </div>
  <div class="containerSubHeader" v-else-if="getNavigation==$options.ORDER_DETAIL" :class="getNavigation">
    <div class="status-container"> 
      <div class="icon" :class="getSelectedOrder.status"></div>
      <div class="value"> {{ getSelectedOrder.status }}</div>
      <div class="last-changed"> {{ getLastChangeStatus }} </div>
    </div>
    <div class="total"> {{ '€ '+getSelectedOrder.total.toFixed(2)}} </div>
    <div class="show-comments" @click="setShowCommentsModal(!getShowCommentsModal)"></div>
    <!-- <div class="show-note" @click="showModal=!showModal" v-if="getSelectedOrder.note"></div>
    <b-modal v-model="showModal" hide-footer hide-header>{{getSelectedOrder.note}}</b-modal> -->
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'containerSubHeader',
  ORDERS_LIST:Static.ORDERS_LIST,
  SEARCH:Static.SEARCH,
  ORDERS:Static.ORDERS_LABEL,
  ORDER_DETAIL:Static.ORDER_DETAIL,
  data(){
    return {
      showModal:false
    }
  },
  computed:{
    ...mapGetters(['startSearching','getResultsNumber','getOrder','getSearchUrl','getNavigation','getSelectedOrder','getShowCommentsModal']),
    // getLastChangeStatus(){
    //   var filteredNotes=this.getSelectedOrder.note.filter(item=>item.comment==null);
    //   filteredNotes.sort();
    //   var dateToReturn=filteredNotes.length>0?filteredNotes[filteredNotes.length-1].created_at:"";
    //   return Static.getLocalDate(dateToReturn).format('ddd DD/MM HH:mm');
    // }
    getLastChangeStatus(){
      return Static.getLocalDate(this.getSelectedOrder.updatedat).format('ddd DD/MM HH:mm');
    }
  },
  methods:{
    ...mapActions({set:'setOrder'}),
    ...mapActions(['setDataFromResponse','setShowCommentsModal']),
    setOrder(order){
      this.set(order);
      axios.get(this.getSearchUrl)
        .then(response => { this.setDataFromResponse(response.data) } )
    },
    getDate(value){
      var options = { weekday: 'short', month: 'numeric', day: 'numeric', hour:'2-digit', minute:'2-digit' };
      return new Date(value).toLocaleString("it-IT", options);
    },
  }
}
</script>

<style src="./ContainerSubHeader.css" scoped></style>