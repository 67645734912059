<template>
  <perfect-scrollbar class="ProductModal" @click="setShowProductModal(false)">
    <div class="modal-container" :class="[getSelectedProduct.header.type,{'with-info':getShowProductModalWithDesc,'from-cart':getShowProductModalFromCart}]" @click.stop="">
      <div class="close-icon" @click="setShowProductModal(false)"></div>
      <div class="upper-part">
        <img class="img" :src="Array.isArray(getSelectedProduct.data.image)?getSelectedProduct.data.image[0]:getSelectedProduct.data.image"/>
        <div class="info-container">
          <div class="name"> {{getSelectedProduct.data.name}} </div>
          <div class="sku"> SKU: <span> {{getSelectedProduct.data.sku}} </span> </div>
          <div class="price_container">
            <!-- <div class="price"> {{isNotZeroOrNull(getSelectedProduct.data.special_price)?'€ '+getSelectedProduct.data.special_price:'€ '+getSelectedProduct.data.price}} </div>
            <div class="special_price" v-if="isNotZeroOrNull(getSelectedProduct.data.special_price)"> {{'€ '+getSelectedProduct.data.price}} </div> -->
            <div class="price"> {{isNotZeroOrNull(getSelectedProduct.pricing['1']['0.0000']['EUR'].selling_price)?'€ '+getSelectedProduct.pricing['1']['0.0000']['EUR'].selling_price:'€ '+getSelectedProduct.pricing['1']['0.0000']['EUR'].listing_price}} </div>
            <div class="special_price" v-if="isNotZeroOrNull(getSelectedProduct.pricing['1']['0.0000']['EUR'].selling_price)"> {{'€ '+getSelectedProduct.pricing['1']['0.0000']['EUR'].listing_price}} </div>
          </div>
          <div class="availability" v-if="getSelectedProduct.warehouses[0].quantity"> Disponibili: {{getSelectedProduct.warehouses[0].quantity}} </div>
          <div class="availability-detail magazzino" v-if="getSelectedProduct.data.invent_m9"> M9: {{getSelectedProduct.data.invent_m9}}</div>
          <div class="availability-detail negozio" v-if="getSelectedProduct.data.invent_negozi"> Neg: {{getSelectedProduct.data.invent_negozi}} </div>
          <div class="availability-detail negozio" v-if="getSelectedProduct.header.type!=='bundle'"> TP: {{eta?eta:'...'}} </div>
        </div>
      </div>
      <div class="middle-part" v-if="getSelectedProduct.header.type==='configurable' && !getShowProductModalFromCart">
        <div class="configuration-title"> Tutte le taglie </div>
        <div class="configurations-container">
          <div class="configuration" v-for="(variant,index) in getSelectedProduct.variants" 
          :class="{'selected':variant.data.sku==(selectedVariant.data?selectedVariant.data.sku:''),'inactive':parseInt(variant.warehouses[0].quantity)<=0}"
          @click="eta=null;selectedVariant=variant;setEta();" 
          :key="'configuration_'+index"> 
            {{variant.data[variant.configurable_attributes[0]]}}
          </div>
        </div>
      </div>
      <div class="middle-part" v-else-if="getSelectedProduct.header.type==='bundle' && !getShowProductModalFromCart">
        <div class="bundle-container" v-for="(item,index) in Object.keys(getBundles)" :key="'bundle_'+index">
          <div class="child-title"> {{getBundles[item][0].data?getBundles[item][0].data.name:""}} </div>
          <div class="configurations-container" v-if="getBundles[item].length>1">
            <div class="configuration" v-for="(variant,index) in getBundles[item]" :key="'configuration_'+index"
            @click="selectProductBundle(item,variant)"
            :class="{'selected':variant.data.sku==(selectedProductsBundle[item] && selectedProductsBundle[item].data?selectedProductsBundle[item].data.sku:''),'inactive':parseInt(variant.warehouses[0].quantity)<=0}">
              {{variant.data[variant.configurable_attributes[0]]}}
            </div>
          </div>
        </div>
      </div>
      <div class="middle-part coupon elimina" v-else-if="getShowProductModalFromCart && getSelectedProduct.discount">
        <div class="sconto-attuale"> 
          Sconto attuale {{(getSelectedProduct.discount.import.includes('%')?'del':'di')}} <span class="value"> {{ ' '+getSelectedProduct.discount.import + (getSelectedProduct.discount.import && getSelectedProduct.discount.import.includes('%')?'':'€') }} </span>
        </div>
        <div v-if="getSelectedProduct.discount" class="elimina-coupon" @click="removeDiscountFromProduct">
          <span> Rimuovi Sconto</span>
        </div>
      </div>
      <div class="middle-part coupon" v-else-if="getShowProductModalFromCart">
        <div class="title" @click="showAccordionCodiceSconto=!showAccordionCodiceSconto"> Codice Sconto 
          <div class="icon" :class="showAccordionCodiceSconto?'expanded':'collapsed'"> </div>
        </div>
        <b-collapse v-model="showAccordionCodiceSconto">
          <div class="content">
            <div class="coupon-search-container">
              <b-form-input
              class="coupon-search"
              :value="couponSearchWord"
              placeholder="Inserisci Codice"
              @keyup.native="onCouponSearchWordInput">
              </b-form-input>
              <span class="loading" v-if="isLoadingCoupon"> 
                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> 
              </span>
              <div v-else class="add-coupon" :class="{'inactive':isLoadingCoupon || !productCoupon.active}" @click="addDiscountToProduct(productCoupon)">
                <span class="loader" v-if="isLoadingCoupon"> 
                  <b-spinner type="grow" label="Spinning"></b-spinner> 
                </span>
                <span v-else> Applica </span>
              </div>
            </div>
            <div class="coupon-info" v-if="productCoupon.active">
              <div> Questo Coupon vale <span class="value"> {{ productCoupon.import + (productCoupon.import && productCoupon.import.includes('%')?'':'€') }} </span> </div>
            </div>
          </div>
        </b-collapse>
        <div class="title" @click="showAccordionScontoManuale=!showAccordionScontoManuale"> Sconto Manuale 
          <div class="icon" :class="showAccordionScontoManuale?'expanded':'collapsed'"> </div>
        </div>
        <b-collapse v-model="showAccordionScontoManuale">
          <div class="content">
            <div class="custom-coupon-name-container">
              <b-form-input class="coupon-name" :state="productCustomCoupon.name!=''" :value="productCustomCoupon.name" placeholder="Nome Coupon" @input="productCustomCoupon.name=$event"> </b-form-input>
              <div class="add-coupon" :class="{'inactive':!isActiveCustomCouponButton}" @click="productCustomCoupon.active=true;addDiscountToProduct(productCustomCoupon)">
                <span> Applica </span>
              </div>
            </div>
            <div class="coupon-value-container">
              <b-form-input class="coupon-value" :state="parseFloat(productCustomCoupon.import)>0" :value="parseFloat(productCustomCoupon.import)" @input="productCustomCoupon.import=parseFloat($event)+(couponType==$options.PERCENT?$options.PERCENT:'')"> </b-form-input>
              <div class="coupon-value-type-container">
                <div class="coupon-value-type" :class="{selected:couponType==$options.EURO}" @click="couponType=$options.EURO;productCustomCoupon.import=productCustomCoupon.import.replace('%','')"> € </div>
                <div class="coupon-value-type" :class="{selected:couponType==$options.PERCENT}" @click="couponType=$options.PERCENT;productCustomCoupon.import=productCustomCoupon.import+'%'"> % </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="lower-part">
        <div class="quantity-container">
          <div class="minus" :class="{inactive:quantity<=1}" v-on:click="quantity--"></div>
          <b-form-input class="quantity" v-model="quantity" @input="onQuantityInput" 
            :state="parseInt(quantity)<=getSelectedProduct.warehouses[0].quantity && parseInt(quantity)>0" 
            aria-describedby="input-live-feedback" ></b-form-input>
          <!-- <b-form-invalid-feedback id="input-live-feedback"> Quantità massima raggiunta </b-form-invalid-feedback> -->
          <div class="plus" :class="{inactive:parseInt(quantity)>=getSelectedProduct.warehouses[0].quantity}" v-on:click="quantity++"></div>
        </div>
        <div v-if="getShowProductModalFromCart" class="add-to-cart" @click="editQuantity">
          Conferma
        </div>
        <div v-else class="add-to-cart" @click="addToCart(getSelectedProduct)" :class="{inactive:isAddToCartInactive}">
          Aggiungi
        </div>
      </div>
    </div>
    <perfect-scrollbar class="desc" @click.stop="" v-if="getShowProductModalWithDesc">
      <div class="title"> Descrizione Prodotto </div>
      <div class="value" v-html="getSelectedProduct.data.description">
      </div>
    </perfect-scrollbar>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import Static from '@/utils/Static';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';

export default {
  name: 'ProductModal',
  EURO:Static.EURO,
  PERCENT:Static.PERCENT,
  LOGIN:Static.LOGIN,
  data() {
    return {
      eta:null,
      quantity:1,
      selectedVariant:{sku:'none'},
      showAccordionCodiceSconto:false,
      showAccordionScontoManuale:false,
      productCoupon:{...Static.EMPTY_COUPON},
      productCustomCoupon:{...Static.EMPTY_COUPON},
      cancelTokenCoupon:null,
      isLoadingCoupon:false,
      couponSearchWord:'',
      couponType:Static.EURO,
      selectedProductsBundle:{}
    }
  },
  mounted(){
    if(this.getProduct.header.type!=='configurable') this.setEta();
    if(this.getProduct.header.type=='bundle') {
      Object.keys(this.getBundles).forEach(bundle=>{ this.selectedProductsBundle[bundle]=this.getBundles[bundle][0] })
      this.$forceUpdate();
    }
    
    this.quantity=this.getShowProductModalFromCart?this.getSelectedProduct.cartQuantity:1;
  },
  computed:{
    ...mapGetters(['getShowProductModalWithDesc','getShowProductModalFromCart','getUrlCheckCoupon','getUser','getUrlEta']),
    ...mapGetters({getProduct:'getSelectedProduct'}),
    isActiveCustomCouponButton(){
      return parseFloat(this.productCustomCoupon.import)>0 && this.productCustomCoupon.name!=''
    },
    getSelectedProduct(){
      if(this.getProduct.header.type==='configurable' && !this.getShowProductModalFromCart){
        var productToReturn=cloneDeep(this.getProduct);
        productToReturn.warehouses[0].quantity=this.selectedVariant.data && this.selectedVariant.sku!=='none'?this.selectedVariant.warehouses[0].quantity:null;
        productToReturn.data.invent_m9=this.selectedVariant.data && this.selectedVariant.data.sku!=='none'?this.selectedVariant.data.invent_m9:null;
        productToReturn.data.id=this.selectedVariant.data && this.selectedVariant.data.sku!=='none'?this.selectedVariant.data.id:null;
        productToReturn.data.sku=this.selectedVariant.data && this.selectedVariant.data.sku!=='none'?this.selectedVariant.data.sku:this.getProduct.data.sku;
        productToReturn.data.invent_negozi=this.selectedVariant.data && this.selectedVariant.data.sku!=='none'?this.selectedVariant.data.invent_negozi:null;
        productToReturn.size=this.selectedVariant.data && this.selectedVariant.data.sku!=='none'?this.selectedVariant.data.size:null;
        return productToReturn;
      }
      else return this.getProduct;
    },
    isAddToCartInactive(){
      return parseInt(this.quantity)>this.getSelectedProduct.warehouses[0].quantity || 
        parseInt(this.quantity)<=0 || 
        (this.getSelectedProduct.header.type==='configurable' && this.selectedVariant.sku==='none');
    },
    getBundles(){
      var dataToReturn = [];
      let newReturn = [];
      let checkEqualBundle = {};
      let checkNewReturn = false;
      this.getSelectedProduct.bundles.forEach(child=>{
        child.data.bundle_selection.split(",").forEach(bundle_selection=>{
          if(!dataToReturn[bundle_selection]) dataToReturn[bundle_selection]=[];
          if(!checkEqualBundle[bundle_selection]) checkEqualBundle[bundle_selection]=[];
          dataToReturn[bundle_selection].push(child);
          checkEqualBundle[bundle_selection].push(child.data.id);
        })
      })

      if (Object.keys(checkEqualBundle).length > 1) {
        checkNewReturn = true;
        let temporaryCheck = [];
        Object.keys(checkEqualBundle).forEach(key => {
          if (!temporaryCheck.length) {
            temporaryCheck = checkEqualBundle[key];
            newReturn[key] = dataToReturn[key];
            return;
          }

          if (JSON.stringify(temporaryCheck) == JSON.stringify(checkEqualBundle[key])) {
            return;
          } else {
            newReturn[key] = dataToReturn[key];
          }
        })
      }

      return checkNewReturn ? newReturn : dataToReturn;
    },
  },methods:{
    ...mapActions({add:'addToCart',addDiscount:'addDiscountToProduct'}),
    ...mapActions(['setShowProductModal','setNavigation','deleteUser','setSelectedProduct','refreshRightContainerContentComponentKey','removeFromCart','refreshRightContainerFooterComponentKey']),
    addDiscountToProduct(discount){
      var product={...this.getSelectedProduct};
      product.discount=discount;
      this.removeFromCart(product.data);
      this.add({'product':product,'quantity':product.cartQuantity});
      this.setSelectedProduct(product);
    },
    setEta(){      
      var getUrlEtaWithParameters=this.getUrlEta
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace(Static.ID_PRODOTTO,this.getSelectedProduct.data.id);
      axios.get(getUrlEtaWithParameters).then( response=>{
        this.eta=response.data.deliverydate;
      })
      .catch(err=>{
        this.eta=null;
        if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }
      })
    },
    addToCart(product){
      var productToAdd={...product};
      if(product.header.type=="bundle")
        productToAdd.selectedBundle={...this.selectedProductsBundle}
      this.add({'product':productToAdd,'quantity':this.quantity});
      this.setShowProductModal(false);
    },
    editQuantity(){
      var product={...this.getSelectedProduct};
      var quantity=this.quantity-product.cartQuantity;
      this.add({'product':product,'quantity':quantity});
      this.setShowProductModal(false);
    },
    onQuantityInput(evt){
      var value=parseInt(evt);
      this.quantity=value<0||value>this.getSelectedProduct.qty?this.quantity:value;
    },
    removeDiscountFromProduct(){
      var product={...this.getSelectedProduct};
      delete product.discount;
      this.removeFromCart(product.data);
      this.add({'product':product,'quantity':product.cartQuantity});
      this.setSelectedProduct(product);
    },
    isNotZeroOrNull(value){ return Static.isNotZeroOrNull(value); },
    onCouponSearchWordInput(evt){
      if(this.cancelTokenCoupon!=null)this.cancelTokenCoupon.cancel('nuova query');
      this.cancelTokenCoupon=axios.CancelToken.source();
      this.isLoadingCoupon=true;
      this.couponSearchWord=evt.target.value;
      var urlCheckCouponWithParameters=this.getUrlCheckCoupon
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace(Static.COUPON_CODE,this.couponSearchWord.replace(' ',''));
      axios.get(urlCheckCouponWithParameters,{cancelToken:this.cancelTokenCoupon.token})
      .then((res)=>{
        if(res.data.active)
          this.productCoupon={
            'import':res.data.import,
            'name':res.data.name,
            'active':true
          }
        else this.productCoupon={...Static.EMPTY_COUPON};
        this.isLoadingCoupon=false;
      })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) this.isLoadingCoupon=false;
        else if(thrown.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
          this.isLoadingCoupon=false;
        }
      });
    },selectProductBundle(item,variant){
      this.selectedProductsBundle[item]=variant;
      this.$forceUpdate();
    }
  }
}
</script>

<style src="./ProductModal.css" scoped/>
