<template>
  <div class="containerHeader" :class="getRightNavigation">
      <Tabs v-if="isTabVisisible"/>
      <div v-else-if="getRightNavigation==$options.CUSTOMERS_LIST" class="customers">
        <div class="back" @click="setSelectedCustomer(null);setRightNavigation($options.CART)">  </div>
        <div class="search"> 
          <b-form-input
            class="customer-search"
            :value="getCustomerSearchWord"
            placeholder="Cerca Utenti Registrati"
            @keyup.native="onCustomerSearchWordInput"> 
          </b-form-input>
        </div>
      </div>  
      <div v-else-if="getRightNavigation==$options.ORDERS" class="customers">
        <div class="search"> 
          <b-form-input
            class="customer-search"
            :value="getOrderSearchWord"
            placeholder="Cerca per Mail, Cognome, C.Articolo, ID"
            @keyup.native="onOrderSearchWordInput"> 
          </b-form-input>
        </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.CUSTOMER_CHOICE">
        <div class="back" @click="setSelectedCustomer(null);setRightNavigation($options.CUSTOMERS_LIST)">  </div>
        <div class="customer-name"> Cliente Registrato </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.SHIPPING_PLACE">
        <div class="back" @click="getRegistrationType==$options.REGISTRATION_TYPE_GUEST?setRightNavigation($options.CUSTOMER_CHOICE):setRightNavigation($options.NEW_CUSTOMER)">  </div>
        <div class="customer-name"> Spedizione </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.ONLY_TELEPHONE">
        <div class="back" @click="setRightNavigation($options.SHIPPING_PLACE)">  </div>
        <div class="customer-name"> Nuovo Telefono </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.NEW_CUSTOMER">
        <div class="back" @click="setSelectedCustomer(null);setUserSearchWord('');setRightNavigation($options.CUSTOMERS_LIST)">  </div>
        <div class="customer-name"> Nuovo Cliente </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.SHIPPING_CHOICE">
        <div class="back" @click="setSelectedAddress({});setRightNavigation($options.SHIPPING_PLACE)">  </div>
        <div class="customer-name"> Spedizione: A Casa </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.NEW_SHIPPING">
        <div class="back" @click="setRightNavigation($options.SHIPPING_CHOICE)">  </div>
        <div class="customer-name"> Nuovo Indirizzo di Spedizione </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.SAVE_NEW_CUSTOMER">
        <div class="back" @click="setEmptyCustomerToAdd();setRightNavigation($options.NEW_CUSTOMER)">  </div>
        <div class="customer-name"> Registra Nuovo Cliente </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.NEW_COUPON">
        <div class="back" @click="setRightNavigation($options.CART)">  </div>
        <div class="customer-name"> Coupon </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.CUSTOM_COUPON">
        <div class="back" @click="setRightNavigation($options.NEW_COUPON)">  </div>
        <div class="customer-name"> Coupon Manuale </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.PAYMENT_METHOD">
        <div class="back" @click="setPaymentType('');setAcconto(0);setRightNavigation($options.CART)">  </div>
        <div class="customer-name"> Metodo di Pagamento </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.ACCONTO">
        <div class="back" @click="setAcconto(0);setRightNavigation($options.PAYMENT_METHOD)">  </div>
        <div class="customer-name"> Aggiungi Acconto </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.NOTE">
        <div class="back" @click="setOrderNoteToAdd(getOrderNote);setRightNavigation($options.CART)">  </div>
        <div class="customer-name"> + Aggiungi Note all’ordine </div>
      </div>
      <div class="customers" v-else-if="getRightNavigation==$options.ORDER_CONFIRMED">
        <div class="back" @click="fromOrderConfirmedToCart()">  </div>
        <div class="customer-name"> Ordine Confermato </div>
      </div>
  </div>
</template>

<script>
import Tabs from './tabs/Tabs';
import {mapGetters, mapActions} from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'containerHeader',
  components:{Tabs},
  CART:Static.CART,
  CUSTOMERS_LIST:Static.CUSTOMERS_LIST,
  CUSTOMER_CHOICE:Static.CUSTOMER_CHOICE,
  SHIPPING_CHOICE:Static.SHIPPING_CHOICE,
  SHIPPING_PLACE:Static.SHIPPING_PLACE,
  NEW_SHIPPING:Static.NEW_SHIPPING,
  NEW_COUPON:Static.NEW_COUPON,
  CUSTOM_COUPON:Static.CUSTOM_COUPON,
  SAVE_NEW_CUSTOMER:Static.SAVE_NEW_CUSTOMER,
  NEW_CUSTOMER:Static.NEW_CUSTOMER,
  PAYMENT_METHOD:Static.PAYMENT_METHOD,
  ORDER_CONFIRMED:Static.ORDER_CONFIRMED,
  ONLY_TELEPHONE:Static.ONLY_TELEPHONE,
  ACCONTO:Static.ACCONTO,
  NOTE:Static.NOTE,
  ORDERS:Static.ORDERS_LABEL,
  REGISTRATION_TYPE_GUEST:Static.REGISTRATION_TYPE_GUEST,
  computed:{
    ...mapGetters(['getRightNavigation','getUrlCustomer','getUser','getSelectedCustomer','getCustomerSearchWord','getUrlCustomers','getOrderNote'
    ,'getUrlGetOrders','getOrderSearchWord','getRegistrationType','getTimeRange','getCancelTokenSearchOrder']),
    isTabVisisible(){ return this.getRightNavigation==Static.CART || this.getRightNavigation==Static.FILTER }
  },methods:{
    ...mapActions(['setRightNavigation','setSelectedCustomer','setCustomersList','setIsLoadingCustomers','setCustomerSearchWord','setEmptyCustomerToAdd'
    ,'setCancelTokenSearchOrder','setOrderNoteToAdd','setTimeRange','setUserSearchWord','setCustomersPage','setNavigation','deleteUser'
    ,'setSelectedAddress','setCart','setCartCoupon','setPaymentType','setAcconto','setOrderNoteToAdd','setOrderSearchWord','setOrderList']),
    onCustomerSearchWordInput(evt){
      this.setCustomerSearchWord(evt.target.value);
      if(evt.keyCode === 13){
        if(evt.target.value==''){
          var customersUrlWithParameters=this.getUrlCustomers
            .replace(Static.EMAIL,this.getUser.mail)
            .replace(Static.TOKEN,this.getUser.token)
            .replace(Static.FROMDATE,'')
            .replace(Static.TODATE,'')
            .replace(Static.SIZE,'50')
            .replace(Static.IDNEGOZIO,this.getUser.id)
            .replace(Static.PAGE,'1');
            this.setIsLoadingCustomers(true);
            axios.get(customersUrlWithParameters)
            .then((response)=>{ 
              this.setCustomersPage(2);
              this.setCustomersList(response.data); 
            })
            .catch(err=>{if(err.response.status==403) {
              this.setNavigation(Static.LOGIN);
              this.deleteUser(this.getUser);
            }})
            .finally(()=>this.setIsLoadingCustomers(false))
        } else {
          var urlCustomerWithParameters=this.getUrlCustomer.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
          switch (Static.getInputType(this.getCustomerSearchWord)){
            case Static.MAIL: 
              urlCustomerWithParameters=urlCustomerWithParameters
                .replace('/phone/CUSTOMER-PHONE/info/CUSTOMER-FIRSTNAME-LASTNAME','')
                .replace(Static.CUSTOMER_EMAIL,encodeURIComponent(this.getCustomerSearchWord.replace(' ','')));
              break;
            case Static.NUMBER: 
              urlCustomerWithParameters=urlCustomerWithParameters
                .replace('/email/CUSTOMER-EMAIL','')
                .replace('/info/CUSTOMER-FIRSTNAME-LASTNAME','')
                .replace(Static.CUSTOMER_PHONE,this.getCustomerSearchWord.replace(' ',''));
              break;
            default: 
              urlCustomerWithParameters=urlCustomerWithParameters
                .replace('/email/CUSTOMER-EMAIL/phone/CUSTOMER-PHONE','')
                .replace(Static.CUSTOMER_FIRSTNAME_LASTNAME,encodeURIComponent(this.getCustomerSearchWord));
              break;
          }
          this.setIsLoadingCustomers(true);
          axios.get(urlCustomerWithParameters)
          .then((res)=>{
            this.setSelectedCustomer(null);
            this.setCustomersList(res.data);
          })
          .catch(err=>{if(err.response.status==403) {
            this.setNavigation(Static.LOGIN);
            this.deleteUser(this.getUser);
          }})
          .finally(()=>this.setIsLoadingCustomers(false))
        }
      }
    },
    fromOrderConfirmedToCart(){
      this.setCart([]);
      this.setSelectedCustomer(null);
      this.setCartCoupon(Static.EMPTY_COUPON);
      this.setPaymentType('');
      this.setAcconto(0);
      this.setOrderNoteToAdd('');
      this.setRightNavigation(Static.CART);
    },
    onOrderSearchWordInput(evt){
      this.setOrderSearchWord(evt.target.value);
      if(evt.keyCode === 13){
        var that=this;
        this.setTimeRange(Static.TODAY_TIME_RANGE);
        if(this.getCancelTokenSearchOrder!=null)this.getCancelTokenSearchOrder.cancel('nuova query');
        this.setCancelTokenSearchOrder(axios.CancelToken.source());
        var urlGetOrdersWithParameters=this.getUrlGetOrders
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace('/fromdate/ANNO-M-G/todate/ANNO-M-G','')
        .replace(Static.IDNEGOZIO,this.getUser.id);
        if(evt.target.value.startsWith('#'))
          urlGetOrdersWithParameters=urlGetOrdersWithParameters
          .replace('/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA','')
          .replace('/sku/SKU-PRODOTTO','')
          .replace(Static.ID_ORDINE,evt.target.value.substr(1,evt.target.value.length));
        else if(Static.EMAIL_REGEX.test(evt.target.value))
          urlGetOrdersWithParameters=urlGetOrdersWithParameters
          .replace('/orderid/'+Static.ID_ORDINE,'')
          .replace(Static.EMAIL_RICERCA,encodeURIComponent(evt.target.value))
          .replace('/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/sku/SKU-PRODOTTO','');
        else
          urlGetOrdersWithParameters=urlGetOrdersWithParameters
          .replace('/orderid/'+Static.ID_ORDINE,'')
          .replace('/email/'+Static.EMAIL_RICERCA,'')
          .replace(Static.COGNOME_RICERCA,evt.target.value)
          .replace(Static.NOME_RICERCA,evt.target.value)
          .replace(Static.SKU_PRODOTTO,evt.target.value)
        this.setIsLoadingCustomers(true);
        axios.get(urlGetOrdersWithParameters,{cancelToken:this.getCancelTokenSearchOrder.token})
        .then((response)=>{ 
          this.setOrderList(Static.refactorOrderList(response.data));
          this.setIsLoadingCustomers(false);
        })
        .catch(err=>{if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }})
        .catch(function (thrown) {
          if (!axios.isCancel(thrown)) that.setIsLoadingCustomers(false);
          else if(thrown.response.status==403) {
            that.setNavigation(Static.LOGIN);
            that.deleteUser(that.getUser);
            that.setIsLoadingCustomers(false);
        }
        });
      }
    }
  }
}
</script>

<style src="./ContainerHeader.css" scoped></style>