<template>
  <div class="SquareButton" :class="{selected:selected}">
    <div class="label"> {{ label }} </div>
  </div>
</template>

<script>

export default {
  name: 'SquareButton',
  props:['label','selected'],
}
</script>

<style src="./SquareButton.css" scoped/>
