<template>
  <div id="app">
    <Login v-if="getNavigation==$options.LOGIN"/>
    <Navigation v-else/>
    <ProductModal v-if="getShowProductModal"/>
    <EtichetteModal v-if="getShowEtichetteModal"/>
    <CommentsModal v-if="getShowCommentsModal"/>
    <Receipt />
    <Menu />
  </div>
</template>

<script>
import Login from "@/views/login/Login";
import Navigation from "@/views/navigation/Navigation";
import ProductModal from "@/components/productModal/ProductModal";
import EtichetteModal from "@/components/etichetteModal/EtichetteModal";
import Receipt from "@/components/receipt/Receipt";
import Menu from "@/components/menu/Menu";
import CommentsModal from "@/components/commentsModal/CommentsModal";
import { mapGetters } from 'vuex';
import Static from "@/utils/Static";

export default {
  name: 'home',
  LOGIN:Static.LOGIN,
  components: {
    Login,Navigation,ProductModal,Menu,Receipt,EtichetteModal,CommentsModal
  },computed:{
    ...mapGetters(['getNavigation','getShowProductModal','getShowReceipt','getShowEtichetteModal','getShowCommentsModal']),
  }
}

</script>

<style src="./App.css"> </style>
