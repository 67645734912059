<template>
  <div class="containerContent" :class="getNavigation">
    <div class="container" :class="{startSearching:startSearching}" v-if="getNavigation==$options.SEARCH">
      <img :src="require('@/assets/logo.jpg')"/>
      <div class="searchbar">
        <img :src="require('@/assets/search.svg')"/>
        <b-form-input
        :value="getSearchWord"
        @input="search($event)"
        placeholder="Cerca Nome, Codice articolo, Attributo">
        </b-form-input>
      </div>
      <div class="desc"> 52.000 Prodotti disponibili </div>
    </div>
    <div class="products-container" :class="{startSearching:startSearching}" v-if="getNavigation==$options.SEARCH">
      <div class="spinner" v-if="isLoadingProducts">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
      <perfect-scrollbar class="products" @ps-y-reach-end="reachEnd" ref="scroll">
        <ProductCard v-for="(product,index) in Object.values(getProducts)" :key="'ricamo_container_'+index" :product="product"/>
      </perfect-scrollbar>
    </div>
    <div class="container" v-if="getNavigation==$options.ORDERS">
      <div class="empty-state">
        <div class="icon"></div>
        <div class="label-bold"> Storico Ordini </div>
        <div class="label"> Seleziona un’ordine per visualizzarne i dettagli. </div>
      </div>
    </div>
    <div class="container" v-else-if="getNavigation==$options.ORDER_DETAIL">
      <perfect-scrollbar class="upper-part">
        <div class="addresses">
          <div class="fatturazione-container">
            <div class="title"> Info Utente </div>
            <div class="content">
              <div class="info"> {{getSelectedOrder.billingaddress.firstname + ' ' + getSelectedOrder.billingaddress.lastname}} </div>
              <div class="info"> {{getSelectedOrder.email}} </div>
              <div class="info"> {{getSelectedOrder.billingaddress.telephone}} </div>
            </div>
          </div>
          <div class="spedizione-container">
            <div class="title"> Indirizzo di Spedizione </div>
            <div class="content">
              <div class="info"> {{getSelectedOrder.shippingaddress.firstname + ' ' + getSelectedOrder.shippingaddress.lastname}} </div>
              <div class="info"> {{getSelectedOrder.shippingaddress.streetname+ ' '+ getSelectedOrder.shippingaddress.streetnumber + ', ' + getSelectedOrder.shippingaddress.city+ ', '+ getSelectedOrder.shippingaddress.postcode + ', '+ getSelectedOrder.shippingaddress.country}} </div>
              <div class="info"> {{getSelectedOrder.shippingaddress.telephone}} </div>
            </div>
          </div>
        </div>
        <div class="prodotti">
          <div class="table-container">
            <div class="row-title">
              <div class="column-prodotto"> Prodotto </div>
              <div class="column-sku"> SKU </div>
              <div class="column-qty"> Quantità </div>
              <div class="column-prezzo"> Prezzo </div>
              <div class="column-discount"> Discount </div>
              <div class="column-total"> Totale Riga </div>
            </div>
            <div class="row-product" v-for="(product,index) in getSelectedOrder.items" :key="'order_product_'+index">
              <div class="column-prodotto">
                <div class="name"> {{ product.name }} </div>
                <div class="label" @click="setSelectedOrderProduct(product);setShowEtichetteModal(true)">
                  <div class="icon" :class="'key_'+(product.label?product.label:0)"></div>
                  <div class="value"> {{ !product.label || product.label==0?'Aggiungi Etichetta':$options.getLabelFromKey(product.label,'label') }} </div>
                  <div class="last-changed"> {{ getLastChangeLabel(product.sku) }} </div>
                </div>
              </div>
              <div class="column-sku"> {{ product.sku }} </div>
              <div class="column-qty"> {{ product.quantity }} </div>
              <div class="column-prezzo"> {{ '€ '+product.price.toFixed(2) }} </div>
              <div class="column-discount"> {{ '€ '+product.discount.toFixed(2) }} </div>
              <div class="column-total"> {{ '€ '+product.total.toFixed(2) }} </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="riepilogo">
        <div class="title"> Riepilogo Ordine </div>
        <div class="eta">
          <div> La data stimata di spedizione ordine è <span> {{ $options.getLastDate(getSelectedOrder.items,'deliverydate') }} </span> </div>
        </div>
        <div class="subtotale">
          <div class="label"> Subtotale </div>
          <div class="value"> {{ '€ ' + getSelectedOrder.subtotal.toFixed(2) }}</div>
        </div>
        <div class="spedizione">
          <div class="label"> Spedizione </div>
          <div class="value"> {{ '€ ' + getSelectedOrder.shippingcost.toFixed(2) }}</div>
        </div>
        <div class="totale">
          <div class="label"> Total </div>
          <div class="value"> {{'€ ' +  getSelectedOrder.total.toFixed(2) }} </div>
        </div>
        <div class="acconto">
          <div class="label"> Acconto </div>
          <div class="value"> {{ '€ ' + getSelectedOrder.totalpaid.toFixed(2) }} </div>
        </div>
        <div class="da-pagare">
          <div class="label"> Totale da pagare</div>
          <div class="value"> {{ '€ ' + getSelectedOrder.totaltopay.toFixed(2) }} </div>
        </div>
      </div>
      <div class="cta">
        <div class="stampa" @click="printReceipt"> Stampa </div>
        <div class="email" @click="sendEmail" :class="{inactive:isLoadingSendEmail}">
          <span v-if="isLoadingSendEmail" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span>
          <span v-else> Invia ordine per Email </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex';
import axios from 'axios';
import ProductCard from '@/components/productCard/ProductCard';
import Static from '@/utils/Static';

export default {
  name: 'containerContent',
  SEARCH:Static.SEARCH,
  ORDERS:Static.ORDERS_LABEL,
  ORDER_DETAIL:Static.ORDER_DETAIL,
  getLabelFromKey:Static.getLabelFromKey,
  getLastDate:Static.getLastDate,
  components:{ProductCard},
  data(){
    return{
      isLoadingProducts:false,
      CancelToken:null,
      isLoadingSendEmail:false,
    }
  },
  computed:{
    ...mapGetters(['getSearchWord','startSearching','getProducts','getSearchUrl','getPage','getResultsNumber','getNavigation',
    'getUser','getSelectedOrder','getUrlSendEmail']),
  },
  methods:{
    ...mapActions(['setSearchWord','setDataFromResponse','setPage','setShowEtichetteModal','setSelectedOrderProduct','setDataToPrint']),
    search(evt){
      // if(this.cancelToken!=null)this.cancelToken.cancel('nuova query');
      // this.cancelToken=axios.CancelToken.source();
      this.setSearchWord(evt);
      this.$refs.scroll.$el.scrollTop = 0
      if(evt.length>=3)
        // axios.get(this.getSearchUrl,{cancelToken:this.cancelToken.token})
        axios.get(this.getSearchUrl)
        .then(response => { this.setDataFromResponse(response.data) } )
        .catch(() => { /* console.log(e) */ } );
      else
        this.setDataFromResponse(null);
    },
    reachEnd(){
      if(Object.keys(this.getProducts).length+Static.ROWS<this.getResultsNumber && !this.isLoadingProducts){
        if(this.cancelToken!=null)this.cancelToken.cancel('nuova query');
        this.cancelToken=axios.CancelToken.source();
        this.isLoadingProducts=true;
        this.setPage(this.getPage+1);
        axios.get(this.getSearchUrl,{cancelToken:this.cancelToken.token})
          .then(response => {
            this.setDataFromResponse({...response.data,...{isPageChanged:true}});
            this.isLoadingProducts=false;
          })
          .catch(() => { /* console.log(e) */ } );
      }
    },
    printReceipt(){
      var dataToPrint={};
      dataToPrint.products=[];
      this.getSelectedOrder.items.forEach(product=>{
        dataToPrint.products.push({
          qty:product.quantity,
          name:product.name,
          sku:product.sku,
          ean:product.ean,
          eta:product.deliverydate,
          total:product.total
        })
      })
      dataToPrint.total=this.getSelectedOrder.total;
      dataToPrint.totalpaid=this.getSelectedOrder.totalpaid;
      dataToPrint.totaltopay=this.getSelectedOrder.totaltopay;
      dataToPrint.customerName=this.getSelectedOrder.customername;
      dataToPrint.id=this.getSelectedOrder.id;
      dataToPrint.date=this.getSelectedOrder.date;
      dataToPrint.lastDate=Static.getLastDate(this.getSelectedOrder.items,'deliverydate');
      dataToPrint.shippingAddress=this.getSelectedOrder.shippingaddress;
      dataToPrint.shippingCost=this.getSelectedOrder.shippingcost;
      this.setDataToPrint(dataToPrint)
      .then(()=>Static.printReceipt())
    },
    sendEmail(){
      // per mandarla a entrambi mettere 'both'
      var objectToSend={
        idorder:this.getSelectedOrder.id,
        emailcopy: this.getUser.mail,
        namecopy: this.getUser.store,
        sendto: "customer"
      }
      var getUrlSendEmailWithParameters=this.getUrlSendEmail
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      this.isLoadingSendEmail=true
      axios.post(getUrlSendEmailWithParameters,objectToSend)
      .then(()=>
        this.$bvToast.toast('Email correttamente Inviata', {
          title: '',
          autoHideDelay: 5000,
          solid:true,
          variant:'success'
        })
      )
      .catch(err=>{if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
      }})
      .finally(()=>this.isLoadingSendEmail=false)
    },
    getLastChangeLabel(sku){
      var filteredNotes=this.getSelectedOrder.note.filter(item=>
        item.comment!=null && item.comment.startsWith("WEBPOS:") && item.comment.split("SKU:")[1].includes(sku)
      );
      filteredNotes.sort();
      var dateToReturn=filteredNotes.length>0?filteredNotes[0].created_at:"";
      return dateToReturn!=""?Static.getLocalDate(dateToReturn).format('ddd DD/MM HH:mm'):"";
    },
    getDate(value){
      var options = { weekday: 'short', month: 'numeric', day: 'numeric', hour:'2-digit', minute:'2-digit' };
      return value!=""?new Date(value).toLocaleString("it-IT", options):"";
    },
  }
}
</script>

<style src="./ContainerContent.css" scoped></style>
