<template>
  <div class="addressAccordion">
    <div class="title" @click="showAccordion=!showAccordion"> Indirizzo di Spedizione {{ index+1 }} 
      <div class="icon" :class="showAccordion?'expanded':'collapsed'"></div>
    </div>
    <b-collapse v-model="showAccordion" class="info-collapse">
        <div class="info-container" @click="setSelectedAddress(address)" :class="{selected:isEqual(getSelectedAddress,address)}">
          <div class="select"></div>
          <div class="info"> {{address.firstname + ' ' + address.lastname}} </div>
          <div class="info"> {{address.address.streetname+ ' '+ (address.address.streetnumber?address.address.streetnumber:'') + ', ' + address.address.city+ ', '+ address.address.postcode + ', '+ address.address.country}} </div>
          <div class="info"> {{address.telephone}} </div>
        </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';

export default {
  name: 'AddressAccordion',
  props: ['index','address'],
  data() {
    return {
      showAccordion:false
    }
  },
  computed:{
    ...mapGetters(['getSelectedAddress'])
  },methods:{
    ...mapActions(['setSelectedAddress']),
    isEqual(a,b){
      return isEqual(a,b);
    }
  },
}
</script>

<style src="./AddressAccordion.css" scoped/>
