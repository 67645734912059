<template>
  <div class="orderAccordion">
    <div class="title" @click="showAccordion=!showAccordion">
      <div class="date-container"> 
        <div class="year"> {{ date.split('-')[0] }} </div>
        <div class="month_day"> {{ date.split('-')[2]+'-'+date.split('-')[1] }} </div>
      </div>
      <div class="icon" :class="showAccordion?'expanded':'collapsed'"></div>
    </div>
    <b-collapse v-model="showAccordion" class="info-collapse">
        <div class="order-item" v-for="(order,orderIndex) in getFormattedOrderList[date]" 
          :key="'order_'+index+'_'+orderIndex" @click="onOrderClick(order)" :class="{selected:isEqual(order,getSelectedOrder)}">
          <div class="row1">
            <div class="status" :class="order.status"> </div>
            <div class="id"> {{ '#'+order.id }}</div>
            <div class="label" v-for="(label,index) in labels(order)" :key="'label_'+index" :class="'key_'+(label?label:0)"></div>
            <div class="total"> {{ '€ '+order.total.toFixed(2) }}</div>
          </div>
          <div class="row2">
            <!-- <div class="name"> {{ order.customername + " - " + order.shippingaddress.telephone}}</div> -->
            <div class="name"> {{ order.billingaddress.firstname+" "+ order.billingaddress.lastname + " - " + order.billingaddress.telephone}}</div>
            <div class="orario"> {{ $options.getLocalDate(order.date).format('HH:mm') }}</div>
          </div>
        </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import Static from '@/utils/Static'

export default {
  name: 'OrderAccordion',
  getLocalDate:Static.getLocalDate,
  props: ['index','date','getFormattedOrderList'],
  data() {
    return {
      showAccordion:true
    }
  },computed:{
    ...mapGetters(['getSelectedOrder'])
  },methods:{
    ...mapActions(['setSelectedOrder','setNavigation']),
    labels(order){
      var arrayToReturn=[];
      order.items.forEach(element => {
        if(!arrayToReturn.includes(element.label)) arrayToReturn.push(element.label)
      });
      return arrayToReturn;
    },
    isEqual(a,b){ return isEqual(a,b); },
    onOrderClick(order){
      if(isEqual(order,this.getSelectedOrder)){
        this.setSelectedOrder(null);
        this.setNavigation(Static.ORDERS_LABEL);
      } else {
        this.setSelectedOrder(Static.refactorOrder(order));
        this.setNavigation(Static.ORDER_DETAIL);
      }
    }
  },
}
</script>

<style src="./OrderAccordion.css" scoped/>
