<template>
  <div class="EtichetteModal" @click="setShowEtichetteModal(false)">
    <div class="modal-container" @click.stop="">
      <div class="loader" v-if="isLoadingLabel"> <b-spinner type="grow" label="Spinning"></b-spinner> </div>
      <div class="close-icon" @click="setShowEtichetteModal(false)"></div>
      <div class="label-container">
        <div class="label" v-for="(label,index) in $options.ETICHETTE_ARRAY" 
        :class="{selected:getSelectedOrderProduct.label==label.key || (!getSelectedOrderProduct.label && label.key==0)}" :key="'label_'+index" @click="setLabel(label.key)">
          <div class="icon" :class="'key_'+label.key"></div>
          <div class="value"> {{label.label}} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'EtichetteModal',
  ETICHETTE_ARRAY:Static.ETICHETTE_ARRAY,
  LOGIN:Static.LOGIN,
  data(){
    return {
      isLoadingLabel:false
    }
  },
  computed:{
    ...mapGetters(['getShowEtichetteModal','getSelectedOrder','getSelectedOrderProduct','getUser','getUrlSetEtichetta','getUrlComment','getUrlGetOrders'
    ,'getTimeRange','getOrderList','getOrderSearchWord']),
  },methods:{
    ...mapActions(['setShowEtichetteModal','setSelectedOrderProduct','setSelectedOrder','setOrderList','setNavigation','deleteUser','setShowCommentsModal']),
    catchError(err){
      if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
      } else {
        this.$bvToast.toast('Qualcosa è andato storto. Prova a pulire la cache', {
          title: '',
          autoHideDelay: 5000,
          solid:true,
          variant:'danger'
        })
      }
      this.isLoadingLabel=false;
    },
    refresh(){
      var getUrlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace('/fromdate/ANNO-M-G/todate/ANNO-M-G','')
      .replace('/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA','')
      .replace('/sku/SKU-PRODOTTO','')
      .replace(Static.IDNEGOZIO,this.getUser.id)
      .replace(Static.ID_ORDINE,this.getSelectedOrder.id);
      this.isLoadingComment=true;
      axios.get(getUrlGetOrdersWithParameters)
      .then(response=>{ this.setSelectedOrder(Static.refactorOrder(response.data[0])) })
      .catch(err=>{if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
        this.setShowCommentsModal(false);
      }})
      .finally(()=>this.isLoadingComment=false)
    },
    async setLabel(labelId){
      // var that=this;
      var objectToSend={
        "idorder": this.getSelectedOrder.id,
        "skuproduct": this.getSelectedOrderProduct.sku,
        "idlabel": labelId
      }
      var urlSetEtichettaWithParameters=this.getUrlSetEtichetta
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token);
      this.isLoadingLabel=true;
      if(Static.isLocalhost()) {
        var selectedOrder={...this.getSelectedOrder};
        var orderProduct={...this.getSelectedOrderProduct};
        orderProduct.label=labelId;
        this.setSelectedOrderProduct(orderProduct);
        var orderList= [...this.getOrderList];
        this.getOrderList.forEach((order,index)=>{
          if(order.id==selectedOrder.id){
            orderList[index].items.forEach((product,productIndex)=>{
              if(product.sku==orderProduct.sku){
                selectedOrder.items[productIndex]=orderProduct
                return false
              }
            })
            orderList[index]=selectedOrder;
            return false
          }
        })
        this.setSelectedOrder(null);
        this.setSelectedOrder(Static.refactorOrder(selectedOrder));
        this.setOrderList(Static.refactorOrderList(orderList));
        this.isLoadingLabel=false;
      } else {
        await axios.post(urlSetEtichettaWithParameters,objectToSend)
        .then(()=>{
          objectToSend={
            "idorder": this.getSelectedOrder.id,
            "comment": "WEBPOS: Etichetta prodotto \"" + this.getSelectedOrderProduct.name + "\" cambiata in \"" + Static.getLabelFromKey(labelId) +"\"\n SKU: "+ this.getSelectedOrderProduct.sku
          };
        })
        .catch(err=>this.catchError(err))
        var getUrlGetCommentWithParameters=this.getUrlComment
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        this.isLoadingLabel=true;
        await axios.post(getUrlGetCommentWithParameters,objectToSend)
        .catch(err=>this.catchError(err))
        .finally(()=>{this.refresh()})
        var getUrlGetOrdersWithParameters=this.getUrlGetOrders
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace('/fromdate/ANNO-M-G/todate/ANNO-M-G','')
        .replace('/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA','')
        .replace('/sku/SKU-PRODOTTO','')
        .replace(Static.IDNEGOZIO,this.getUser.id)
        .replace(Static.ID_ORDINE,this.getSelectedOrder.id);
        this.isLoadingComment=true;
        await axios.get(getUrlGetOrdersWithParameters)
        .then(response=>{ 
          var orderProduct={...this.getSelectedOrderProduct};
          orderProduct.label=labelId;
          this.setSelectedOrderProduct(orderProduct);
          var orderList=[...this.getOrderList];
          this.getOrderList.forEach((order,index)=>{
            if(order.id==this.getSelectedOrder.id){
              orderList[index]=response.data[0]
              return false
            }
          })
          this.setOrderList(Static.refactorOrderList(orderList));
          this.setSelectedOrder(Static.refactorOrder(response.data[0]));
        })
        .catch(err=>this.catchError(err))
        .finally(()=>{this.refresh()})
        this.isLoadingLabel=false;
      }
    }
  }
}
</script>

<style src="./EtichetteModal.css" scoped/>
