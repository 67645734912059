import idb from '@/utils/indexedDBService';

export default {
    actions:{
        async setUser(context, user) {
            await idb.saveUser(user);
            context.commit('setUser',user);
        },
        async checkUser(context) {
            let user = await idb.getUser();
            context.commit('setUser',user[0]);
        },
        async deleteUser(context,user) {
            await idb.deleteUser(user);
            context.commit('setUser',null);
        },
    }
}