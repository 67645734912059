<template>
  <div class="menu" :class="{'shown':getShowMenu}" @click="setShowMenu(false)">
    <div class="opacitone">  </div>
    <div class="navigation" @click.stop="">
      <div class="logo"></div>
      <div class="info"> 
        <div class="name"> {{getUser?getUser.store:''}} </div>
        <div class="address"> {{getUser?(getUser.address.streetname+ ' '+ getUser.address.streetnumber+", "+getUser.address.postcode+", "+getUser.address.city):''}} </div>
      </div>
      <div class="navigation-list">
        <div class="navigation-item" :class="{selected:getNavigation==$options.SEARCH}" @click="toCheckout"> 
          <span class="icon checkout"/> checkout </div>
        <div class="navigation-item" :class="{selected:getNavigation==$options.ORDERS||getNavigation==$options.ORDER_DETAIL}" @click="toOrders()"> 
          <span class="icon orders"/> Storico Ordini </div>
        <div class="navigation-item" @click="setShowMenu(false);setNavigation($options.LOGIN);deleteUser(getUser)"> 
          <span class="icon logout"/> logout </div>
        <div class="navigation-item version">
          Versione 1.0.3
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'Menu',
  SEARCH:Static.SEARCH,
  ORDERS:Static.ORDERS_LABEL,
  LOGIN:Static.LOGIN,
  ORDER_DETAIL:Static.ORDER_DETAIL,
  computed:{
    ...mapGetters(['getShowMenu','getUser','getNavigation','getUser','getUrlGetOrders','getTimeRange'])
  },
  methods:{
    ...mapActions(['setShowMenu','setNavigation','deleteUser','setRightNavigation','setOrderSearchWord','setTimeRange','setIsLoadingCustomers','setOrderList','setCart'
    ,'setSelectedCustomer','setCartCoupon','setPaymentType','setAcconto','setOrderNoteToAdd']),
    getTimeRangeFromDays(days){
      var date=new Date();
      var dateDays=new Date();
      dateDays.setDate(date.getDate()-days);
      return {
        start:new Date(dateDays.getFullYear(),dateDays.getMonth(),dateDays.getDate()),
        end:new Date(date.getFullYear(),date.getMonth(),date.getDate())
      }
    },
    clearSelectedCustomer(){
      this.setCart([]);
      this.setSelectedCustomer(null);
      this.setCartCoupon(Static.EMPTY_COUPON);
      this.setPaymentType('');
      this.setAcconto(0);
      this.setOrderNoteToAdd('');
    },
    toOrders(){
      this.setShowMenu(false);
      this.setNavigation(Static.ORDERS_LABEL);
      this.setRightNavigation(Static.ORDERS_LABEL);
      this.clearSelectedCustomer();
      var that=this;
      this.setOrderSearchWord('');
      this.setTimeRange(this.getTimeRangeFromDays(30));
      var urlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace(Static.FROMDATE,'fromdate/'+Static.changeDateFormat(this.getTimeRange.start))
      .replace(Static.TODATE,'todate/'+Static.changeDateFormat(this.getTimeRange.end))
      .replace("/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/orderid/ID-ORDINE/sku/SKU-PRODOTTO",'')
      .replace(Static.IDNEGOZIO,this.getUser.id);
      this.setIsLoadingCustomers(true);
      axios.get(urlGetOrdersWithParameters)
      .then((response)=>{ 
        this.setOrderList(Static.refactorOrderList(response.data)); 
        this.setIsLoadingCustomers(false);
      })
      .catch(err=>{if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
      }})
      .finally(()=>that.setIsLoadingCustomers(false))
    },
    toCheckout(){
      this.setShowMenu(false);
      this.clearSelectedCustomer();
      this.setNavigation(Static.SEARCH);
      this.setRightNavigation(Static.CART)
    }
  }
}
</script>

<style src="./Menu.css" scoped/>
