<template>
  <div class="HoldCarts-container" :class="{expanded:expanded}">
    <div class="counter" @click="expanded=!expanded"> 
      <span> {{expanded?'':getHoldCarts.length }} </span> 
    </div>
    <perfect-scrollbar class="content">
      <div class="hold-item" v-for="(cart,index) in getHoldCarts" :key="'cart_'+index" @click="holdItemClick(cart,index)">
        <div class="index"> {{ index+1 }} </div>
        <div class="total"> {{ getTotal(cart) }} </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Static from '@/utils/Static';

export default {
  name: 'HoldCarts',
  data(){
    return {
      expanded:false,
    }
  },
  computed:{
    ...mapGetters(['getHoldCarts'])
  },methods:{
    ...mapActions(['setCart','removeFromHoldCarts']),
    getTotal(cart){ return Static.getTotal(cart); },
    holdItemClick(cart,index){
      this.expanded=false;
      this.removeFromHoldCarts(index);
      this.setCart(cart);
    }
  }
}
</script>

<style src="./HoldCarts.css" scoped/>
