<template>
  <div class="FilterAccordion">
    <div class="title" :class="type" @click="showAccordion=!showAccordion"> {{ label }} 
      <div v-if="type!='slider'" class="icon" :class="showAccordion?'expanded':'collapsed'"></div>
    </div>
    <b-collapse v-if="type!='slider'" v-model="showAccordion">
      <perfect-scrollbar class="filter_container">
        <div v-for="(filter,index) in filtersWithoutActive" :key="'filter_'+index" class="filter-item" @click="addActiveFilter({key:filterKey,label:label,value:index})"> 
          <div class="filter"> {{categoriesData(index)}} </div>
          <div class="num"> {{filter}} </div>
        </div>
      </perfect-scrollbar>
    </b-collapse>
    <div v-else class="slider_container">
      <vue-slider :value="getSliderValue" :min="getSliderMinMax[0]" :max="getSliderMinMax[1]" :interval=1 @drag-end="onDragEnd" @dragging="onDragging"></vue-slider>
      <div class="slider_values" > Da € <span>{{getSliderValue[0]}}</span> - a - € <span>{{getSliderValue[1]}}</span></div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import axios from 'axios';
import Static from '@/utils/Static';

export default {
  name: 'FilterAccordion',
  props: ['type','filters','label','filterKey'],
  data() {
    return {
      showAccordion:false,
      sliderValue:[]
    }
  },
  computed:{
    ...mapGetters(['getSearchUrl','getActiveFilters','getSliderValue','getSliderMinMax']),
    filtersWithoutActive(){
      return Static.filtersWithoutActive(this.getActiveFilters,this.filters,this.filterKey);
    },
  },methods:{
    ...mapActions({addFilter:'addActiveFilter'}),
    ...mapActions(['setDataFromResponse','setSliderValue']),
    addActiveFilter(activeFilter){
      this.addFilter(activeFilter);
      axios.get(this.getSearchUrl)
      .then(response => { this.setDataFromResponse(response.data) } )
    },
    categoriesData(value){
      return this.label==='Categorie'?value.split('/')[value.split('/').length-1]:value
    },
    onDragEnd(){ this.addActiveFilter({key:this.filterKey,label:this.label,value:[this.getSliderValue[0],this.getSliderValue[1]]}) },
    onDragging(evt){ this.setSliderValue([evt[0],evt[1]]) },
  },mounted() {
      this.sliderValue=this.getSliderValue;
  },
}
</script>

<style src="./FilterAccordion.css" scoped/>
