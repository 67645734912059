<template>
  <div class="tabs">
      <Tab :name="$options.FILTER"/>
      <Tab :name="$options.CART"/>
  </div>
</template>

<script>
import Static from '@/utils/Static';
import Tab from '@/components/tab/Tab';

export default {
  name: 'tabs',
  FILTER:Static.FILTER,
  CART:Static.CART,
  components:{Tab},
}
</script>

<style src="./Tabs.css" scoped></style>