<template>
  <div class="containerHeader" :class="getNavigation">
    <img class="menu" :src="require('@/assets/menu.svg')" @click="setShowMenu(true)"/>
    <!-- <img class="menu" :src="require('@/assets/menu.svg')"/> -->
    <img class="logo" :src="require('@/assets/logo.jpg')" v-if="getNavigation==$options.ORDERS"/>
    <img class="expand" :src="require('@/assets/expand.svg')" @click="setFullscreen" v-else-if="getNavigation==$options.SEARCH"/>
    <div class="id-order" v-else-if="getNavigation==$options.ORDER_DETAIL"> 
      <div class="label"> Ordine </div>
      <div class="value"> {{'#'+getSelectedOrder.id}} </div>
    </div>
    <div class="date-order" v-if="getNavigation==$options.ORDER_DETAIL">
      <div class="date">{{ $options.getLocalDate(getSelectedOrder.date).format("dddd DD MMM YYYY") }} </div>
      <div class="orario"> {{'Ore ' + $options.getLocalDate(getSelectedOrder.date).format("HH:mm")}} </div>
    </div>
  </div>
</template>

<script>
import Static from '@/utils/Static';
import { mapActions,mapGetters } from 'vuex';

export default {
  name: 'containerHeader',
  ORDERS:Static.ORDERS_LABEL,
  ORDER_DETAIL:Static.ORDER_DETAIL,
  getLocalDate:Static.getLocalDate,
  computed:{
    ...mapGetters(['getNavigation','getSelectedOrder']),
    getLongDate(){
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(this.getSelectedOrder.date).toLocaleString("it-IT", options);
    },
  },
  methods:{
    ...mapActions(['setShowMenu']),
    setFullscreen(){ Static.openFullscreen(document.getElementById('app')); }
  }
}
</script>

<style src="./ContainerHeader.css" scoped></style>