<template>
  <perfect-scrollbar class="CommentsModal" @click="setShowCommentsModal(false)">
    <div class="modal-container" @click.stop="">
      <div class="loader" v-if="isLoadingComment"> <b-spinner type="grow" label="Spinning"></b-spinner> </div>
      <div class="close-icon" @click="setShowCommentsModal(false)"></div>
      <div class="comment-input">
        <b-form-textarea
          :value="comment"
          no-resize
          class="input"
          placeholder="Aggiungi commento per l’ordine…"
          @keyup.native="(evt)=>{comment=evt.target.value}">
        </b-form-textarea>
        <div class="cta">
          <div class="refresh" @click="refresh">  </div>
          <div :class="['save-comment',{inactive:comment==''}]" @click="addComment"> Aggiungi commento </div>
        </div>
      </div>
      <perfect-scrollbar class="comment-history">
        <div class="item" v-for="(item,index) in getSelectedOrder.note" :key="'item_'+index">
          <div class="timestamp"> {{$options.getLocalDate(item.created_at).format('DD/MM HH:mm')}} </div>
          <div class="message"> {{item.comment!=null?item.comment:"NUOVO STATO ORDINE: " + item.status}} </div>
        </div>
      </perfect-scrollbar>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';

export default {
  name: 'CommentsModal',
  getLocalDate: Static.getLocalDate,
  ETICHETTE_ARRAY:Static.ETICHETTE_ARRAY,
  data(){
    return {
      isLoadingComment:false,
      comment:""
    }
  },
  computed:{
    ...mapGetters(['getShowCommentsModal','getSelectedOrder','getUser','getUrlGetOrders','getUrlComment']),
  },methods:{
    ...mapActions(['setShowCommentsModal','setSelectedOrder','setShowMenu','setNavigation','deleteUser']),
    refresh(){
      var getUrlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace('/fromdate/ANNO-M-G/todate/ANNO-M-G','')
      .replace('/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA','')
      .replace('/sku/SKU-PRODOTTO','')
      .replace(Static.IDNEGOZIO,this.getUser.id)
      .replace(Static.ID_ORDINE,this.getSelectedOrder.id);
      this.isLoadingComment=true;
      axios.get(getUrlGetOrdersWithParameters)
      .then(response=>{ this.setSelectedOrder(Static.refactorOrder(response.data[0])) })
      .catch(err=>{if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
        this.setShowCommentsModal(false);
      }})
      .finally(()=>this.isLoadingComment=false)
    },
    addComment(){
      var objectToSend={
        "idorder": this.getSelectedOrder.id,
        "comment": this.comment
      };
      var getUrlGetCommentWithParameters=this.getUrlComment
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      this.isLoadingComment=true;
      axios.post(getUrlGetCommentWithParameters,objectToSend)
      .then(()=>{ this.comment="";})
      .finally(()=>this.refresh());
    }
  }
}
</script>

<style src="./CommentsModal.css" scoped/>
