import Vue from 'vue'
import Vuex from 'vuex'
import idb from './idb';
import Static from '@/utils/Static';
import rest from './rest';
import cloneDeep from 'lodash/cloneDeep';

Vue.use(Vuex);

export default new Vuex.Store({
  strict:true,
  state: {
    navigation:Static.LOGIN,
    rightNavigation:Static.CART,
    searchWord:'',
    user:null,
    products:[],
    showProductModal:false,
    showEtichetteModal:false,
    showMenu:false,
    selectedProduct:null,
    showProductModalWithDesc:false,
    showProductModalFromCart:false,
    resultsNumber:0,
    filterCategories:{},
    filterPrices:{},
    filterManufacturers:{},
    activeFilters:[],
    cart:[],
    customersList:[],
    selectedCustomer:null,
    holdCarts:[],
    isLoadingCustomers:false,
    customerSearchWord:'',
    selectedShipping:Static.SHOP,
    order:Static.ORDERS_LIST[0],
    page:1,
    sliderMinMax:[],
    sliderValue:[],
    selectedAddress:{},
    addressToAdd:cloneDeep(Static.EMPTY_ADDRESS),
    isLoadingCustomer:false,
    isLoadingCoupon:false,
    customerToAdd:cloneDeep(Static.EMPTY_CUSTOMER),
    registrationType:Static.REGISTRATION_TYPE_USER,
    cartCoupon:{...Static.EMPTY_COUPON},
    couponToAdd:{...Static.EMPTY_COUPON},
    rightContainerContentComponentKey:0,
    rightContainerFooterComponentKey:0,
    paymentType:"",
    acconto:0,
    showReceipt:false,
    completedOrder:{...Static.COMPLETED_ORDER_SAMPLE},
    orderNote:'',
    orderNoteToAdd:'',
    selectedOrder:null,
    selectedOrderProduct:null,
    timeRange:{...Static.EMPTY_TIME_RANGE},
    orderList:[],
    orderSearchWord:'',
    cancelTokenSearchOrder:null,
    dataToPrint:null,
    cartRules:[],
    userSearchWord:'',
    acceptedGDPR:false,
    cartTotal:0,
    cartDiscount:0,
    customersPage:1,
    isValidMail:true,
    showCommentsModal:false,
    telephone:"",
    lastUpdate:0,
    missingInfo:{...Static.EMPTY_MISSING_INFO}
  },
  getters:{
    getRightContainerContentComponentKey:state=>state.rightContainerContentComponentKey,
    getRightContainerFooterComponentKey:state=>state.rightContainerFooterComponentKey,
    getOrderSearchWord:state=>state.orderSearchWord,
    getIsValidMail:state=>state.isValidMail,
    getTelephone:state=>state.telephone,
    getLastUpdate:state=>state.lastUpdate,
    getCartDiscount:state=>state.cartDiscount,
    getShowCommentsModal:state=>state.showCommentsModal,
    getCustomersPage:state=>state.customersPage,
    getUserSearchWord:state=>state.userSearchWord,
    getAcceptedGDPR:state=>state.acceptedGDPR,
    getCartRules:state=>state.cartRules,
    getDataToPrint:state=>state.dataToPrint,
    getCancelTokenSearchOrder:state=>state.cancelTokenSearchOrder,
    getSearchUrl:state=>{
      var urlToReturn=Static.getBaseUrl();
      // var urlToReturn=(window.location.hostname==="www.motoabbigliamento.it"?Static.PROD_BASE_URL:Static.DEV_BASE_URL)+Static.BASE_SEARCH_URL;
      state.activeFilters.forEach((item,index)=>{
        urlToReturn+=item.key==='selling_price'?
          "&filters["+(index+1)+"][0]["+item.key+"][from]="+item.value[0]+"&filters["+(index+1)+"][0]["+item.key+"][to]="+item.value[1]:
          "&filters["+(index+1)+"][]["+item.key+"]="+item.value
      })
      urlToReturn+='&sort[]['+state.order.key+']='+state.order.value;
      urlToReturn+='&offset='+(state.page-1)*Static.ROWS;
      urlToReturn+='&originated_at='+Date.now();
      urlToReturn+=("&q="+state.searchWord);
      return urlToReturn
    },
    getUser:state=>state.user,
    getOrderNote:state=>state.orderNote,
    getOrderNoteToAdd:state=>state.orderNoteToAdd,
    getShowReceipt:state=>state.showReceipt,
    getCompletedOrder:state=>state.completedOrder,
    getOrder:state=>state.order,
    getOrderList:state=>state.orderList,
    getSelectedOrderProduct:state=>state.selectedOrderProduct,
    getSelectedOrder:state=>state.selectedOrder,
    getPaymentType:state=>state.paymentType,
    getAcconto:state=>state.acconto,
    getSelectedAddress:state=>state.selectedAddress,
    getRegistrationType:state=>state.registrationType,
    getCustomerToAdd:state=>state.customerToAdd,
    getCouponToAdd:state=>state.couponToAdd,
    getCartCoupon:state=>state.cartCoupon,
    getShowMenu:state=>state.showMenu,
    getCustomerSearchWord:state=>state.customerSearchWord,
    getTimeRange:state=>state.timeRange,
    getSelectedShipping:state=>state.selectedShipping,
    getIsLoadingCustomers:state=>state.isLoadingCustomers,
    getIsLoadingCustomer:state=>state.isLoadingCustomer,
    getIsLoadingCoupon:state=>state.isLoadingCoupon,
    getShowProductModal:state=>state.showProductModal,
    getShowEtichetteModal:state=>state.showEtichetteModal,
    getSelectedCustomer:state=>state.selectedCustomer,
    getShowProductModalWithDesc:state=>state.showProductModalWithDesc,
    getShowProductModalFromCart:state=>state.showProductModalFromCart,
    getSelectedProduct:state=>state.selectedProduct,
    getProducts:state=>state.products,
    getSearchWord:state=>state.searchWord,
    getNavigation:state=>state.navigation,
    getRightNavigation:state=>state.rightNavigation,
    getCartCounter:state=>{
      var counter=0;
      state.cart.forEach(item=>{ counter+=item.cartQuantity });
      return counter;
    },
    getFilterCounter:state=>state.activeFilters.length,
    getResultsNumber:state=>state.resultsNumber,
    getFilterCategories:state=>state.filterCategories,
    getFilterPrices:state=>state.filterPrices,
    getFilterManufacturers:state=>state.filterManufacturers,
    startSearching:state=>state.searchWord.length>=3,
    getActiveFilters:state=>state.activeFilters,
    getCart:state=>state.cart,
    getPage:state=>state.page,
    getCustomersList:state=>state.customersList,
    getCartTotal:state=>state.cartTotal,
    getTotal:state=>{
      var total=0;
      state.cart.forEach(item=>{
        var priceToUse=Static.isNotZeroOrNull(item.pricing['1']['0.0000']['EUR'].selling_price)?
          item.pricing['1']['0.0000']['EUR'].selling_price:
          item.pricing['1']['0.0000']['EUR'].listing_price;
        var discountToUse=item.discount?item.discount.import:'0';
        if(discountToUse.includes('%')) total+=priceToUse*item.cartQuantity*(100-parseFloat(discountToUse))/100;
        else total+=(priceToUse-parseFloat(discountToUse))*item.cartQuantity;
      });
      total=total.toFixed(2); //arrotondamento
      // total=Math.floor(total*100)/100; troncamento
      var cartDiscount=state.cartCoupon?state.cartCoupon.import:'0';
      if(cartDiscount.includes('%')) total=total*(100-parseFloat(cartDiscount))/100;
      else total=total-parseFloat(cartDiscount);
      return total=total.toFixed(2);
      // return Math.floor(total*100)/100; troncamento
    },
    getTotalToPay:state=>{
      var total=0;
      state.cart.forEach(item=>{
        var priceToUse=Static.isNotZeroOrNull(item.pricing['1']['0.0000']['EUR'].selling_price)?
          item.pricing['1']['0.0000']['EUR'].selling_price:
          item.pricing['1']['0.0000']['EUR'].listing_price;
        var discountToUse=item.discount?item.discount.import:'0';
        if(discountToUse.includes('%')) total+=priceToUse*item.cartQuantity*(100-parseFloat(discountToUse))/100;
        else total+=(priceToUse-parseFloat(discountToUse))*item.cartQuantity;
      });
      total=total.toFixed(2); //arrotondamento
      // total=Math.floor(total*100)/100; troncamento
      var cartDiscount=state.cartCoupon?state.cartCoupon.import:'0';
      if(cartDiscount.includes('%')) total=total*(100-parseFloat(cartDiscount))/100;
      else total=total-parseFloat(cartDiscount);
      total-=state.acconto;
      return total=total.toFixed(2);
      // return Math.floor(total*100)/100; troncamento
    },
    getHoldCarts:state=>state.holdCarts,
    getSliderMinMax:state=>state.sliderMinMax,
    getSliderValue:state=>state.sliderValue,
    getMissingInfo:state=>state.missingInfo,
    getAddressToAdd:state=>state.addressToAdd,
    getValidAddress:state=>state.addressToAdd.country!='' && state.addressToAdd.firstname!='' && state.addressToAdd.lastname!='' && state.addressToAdd.postcode!='' && state.addressToAdd.city!='' && state.addressToAdd.region!='' && state.addressToAdd.streetname!='',
  },
  mutations: {
    setNavigation:(state,navigation)=>state.navigation=navigation,
    setOrder:(state,order)=>state.order=order,
    setIsValidMail:(state,isValidMail)=>state.isValidMail=isValidMail,
    setCartTotal:(state,cartTotal)=>state.cartTotal=cartTotal,
    setShowCommentsModal:(state,showCommentsModal)=>state.showCommentsModal=showCommentsModal,
    setCartRules:(state,cartRules)=>state.cartRules=cartRules,
    setCustomersPage:(state,customersPage)=>state.customersPage=customersPage,
    setCartDiscount:(state,cartDiscount)=>state.cartDiscount=cartDiscount,
    setCancelTokenSearchOrder:(state,cancelTokenSearchOrder)=>state.cancelTokenSearchOrder=cancelTokenSearchOrder,
    setOrderList:(state,orderList)=>state.orderList=orderList,
    setAcceptedGDPR:(state,acceptedGDPR)=>state.acceptedGDPR=acceptedGDPR,
    setDataToPrint:(state,dataToPrint)=>state.dataToPrint=dataToPrint,
    setOrderSearchWord:(state,orderSearchWord)=>state.orderSearchWord=orderSearchWord,
    setTelephone:(state,telephone)=>state.telephone=telephone,
    setUserSearchWord:(state,userSearchWord)=>state.userSearchWord=userSearchWord,
    setShowReceipt:(state,showReceipt)=>state.showReceipt=showReceipt,
    setSelectedOrderProduct:(state,selectedOrderProduct)=>state.selectedOrderProduct=selectedOrderProduct,
    setSelectedOrder:(state,selectedOrder)=>state.selectedOrder=selectedOrder,
    setCompletedOrder:(state,order)=>state.completedOrder=order,
    setTimeRange:(state,timeRange)=>state.timeRange=timeRange,
    refreshRightContainerContentComponentKey:(state)=>state.rightContainerContentComponentKey++,
    refreshRightContainerFooterComponentKey:(state)=>state.rightContainerFooterComponentKey++,
    setAddressToAdd:(state,addressToAdd)=>state.addressToAdd[addressToAdd.type]=addressToAdd.value,
    setCustomerToAdd:(state,customerToAdd)=>state.customerToAdd[customerToAdd.type]=customerToAdd.value,
    setEmptyCustomerToAdd:(state)=>state.customerToAdd=cloneDeep(Static.EMPTY_CUSTOMER),
    addAddressToSelectedCustomer:(state,addressToAdd)=>state.selectedCustomer.addresses.push({...addressToAdd}),
    setCustomerAddressToAdd:(state,customerToAdd)=>state.customerToAdd.addresses[0][customerToAdd.type]=customerToAdd.value,
    setEmptyAddressToAdd:(state)=>state.addressToAdd={...Static.EMPTY_ADDRESS},
    setSliderMinMax:(state,sliderMinMax)=>state.sliderMinMax=sliderMinMax,
    setOrderNote:(state,orderNote)=>state.orderNote=orderNote,
    setOrderNoteToAdd:(state,orderNoteToAdd)=>state.orderNoteToAdd=orderNoteToAdd,
    setSliderValue:(state,sliderValue)=>state.sliderValue=sliderValue,
    setPage:(state,page)=>state.page=page,
    setPaymentType:(state,paymentType)=>state.paymentType=paymentType,
    setAcconto:(state,acconto)=>state.acconto=acconto,
    setCartCoupon:(state,cartCoupon)=>state.cartCoupon=cartCoupon,
    setCouponToAdd:(state,couponToAdd)=>state.couponToAdd[couponToAdd.type]=couponToAdd.value,
    setRegistrationType:(state,registrationType)=>state.registrationType=registrationType,
    addHoldCarts:(state,cart)=>state.holdCarts.push(cart),
    setShowMenu:(state,showMenu)=>state.showMenu=showMenu,
    setSelectedShipping:(state,selectedShipping)=>state.selectedShipping=selectedShipping,
    setCustomerSearchWord:(state,customerSearchWord)=>state.customerSearchWord=customerSearchWord,
    setSelectedAddress:(state,selectedAddress)=>state.selectedAddress=selectedAddress,
    setIsLoadingCustomers:(state,isLoadingCustomers)=>state.isLoadingCustomers=isLoadingCustomers,
    setIsLoadingCoupon:(state,isLoadingCoupon)=>state.isLoadingCoupon=isLoadingCoupon,
    setIsLoadingCustomer:(state,isLoadingCustomer)=>state.isLoadingCustomer=isLoadingCustomer,
    removeFromHoldCarts:(state,cartIndex)=>{ if(cartIndex>-1) state.holdCarts.splice(cartIndex,1 )},
    setCustomersList:(state,customersList)=>state.customersList=customersList,
    setSelectedCustomer:(state,selectedCustomer)=>state.selectedCustomer=selectedCustomer,
    setShowProductModal:(state,showProductModal)=>state.showProductModal=showProductModal,
    setShowEtichetteModal:(state,showEtichetteModal)=>state.showEtichetteModal=showEtichetteModal,
    setShowProductModalFromCart:(state,showProductModalFromCart)=>state.showProductModalFromCart=showProductModalFromCart,
    setShowProductModalWithDesc:(state,showProductModalWithDesc)=>state.showProductModalWithDesc=showProductModalWithDesc,
    setSelectedProduct:(state,selectedProduct)=>state.selectedProduct=selectedProduct,
    setRightNavigation:(state,rightNavigation)=>state.rightNavigation=rightNavigation,
    setSearchWord:(state,searchWord)=>state.searchWord=searchWord,
    setUser:(state,user)=>state.user=user,
    setProducts:(state,products)=>state.products=products,
    setLastUpdate:(state,lastUpdate)=>state.lastUpdate=lastUpdate,
    setResultsNumber:(state,resultsNumber)=>state.resultsNumber=resultsNumber,
    setFilterCategories:(state,filterCategories)=>state.filterCategories=filterCategories,
    setFilterPrices:(state,filterPrices)=>state.filterPrices=filterPrices,
    setFilterManufacturers:(state,filterManufacturers)=>state.filterManufacturers=filterManufacturers,
    setActiveFilters:(state,activeFilters)=>state.activeFilters=activeFilters,
    setMissingInfo:(state,missingInfo)=>state.missingInfo=missingInfo,
    setMissingInfoByType:(state,missingInfo)=>state.missingInfo[missingInfo.type]=missingInfo.value,
    addActiveFilter:(state,activeFilter)=>{
      var indexFilter=Static.checkKey(state.activeFilters,activeFilter);
      if(indexFilter===-1 || (activeFilter.key==="category")) state.activeFilters.push(activeFilter);
      else Vue.set(state.activeFilters,indexFilter,activeFilter);
    },
    setCart:(state,cart)=>state.cart=cart,
    addToCart:(state,data)=>{
      var index=Static.checkSku(state.cart,data.product.data);
      if(index==-1) {
        Vue.set(data.product,'cartQuantity',data.quantity);
        state.cart.push(data.product);
      }
      else state.cart[index].cartQuantity+=data.quantity;
    },
    addDiscountToProduct:(state,data)=>{
      var index=Static.checkSku(state.cart,data.product.data);
      state.cart[index].discount=data.discount
    },
    removeFromCart:(state,product)=>{
      var index = Static.checkSku(state.cart,product);
      if(index>-1) state.cart.splice(index,1)
    },
    removeActiveFilter:(state,activeFilter)=>state.activeFilters.splice( state.activeFilters.indexOf(activeFilter),1),
  },
  actions: {
    setNavigation(context,navigation){ context.commit('setNavigation',navigation); },
    setOrder(context,order){ context.commit('setOrder',order); },
    setIsValidMail(context,isValidMail){ context.commit('setIsValidMail',isValidMail); },
    setCartTotal(context,cartTotal){ context.commit('setCartTotal',cartTotal); },
    setCustomersPage(context,customersPage){ context.commit('setCustomersPage',customersPage); },
    setCartDiscount(context,cartDiscount){ context.commit('setCartDiscount',cartDiscount); },
    setCartRules(context,cartRules){ context.commit('setCartRules',cartRules); },
    setTelephone(context,telephone){ context.commit('setTelephone',telephone); },
    setShowCommentsModal(context,showCommentsModal){ context.commit('setShowCommentsModal',showCommentsModal); },
    setCancelTokenSearchOrder(context,cancelTokenSearchOrder){ context.commit('setCancelTokenSearchOrder',cancelTokenSearchOrder); },
    setOrderList(context,orderList){ context.commit('setOrderList',orderList); },
    setAcceptedGDPR(context,acceptedGDPR){ context.commit('setAcceptedGDPR',acceptedGDPR); },
    setDataToPrint(context,data){ context.commit('setDataToPrint',data); },
    setOrderSearchWord(context,orderSearchWord){ context.commit('setOrderSearchWord',orderSearchWord); },
    setUserSearchWord(context,userSearchWord){ context.commit('setUserSearchWord',userSearchWord); },
    setCompletedOrder(context,order){ context.commit('setCompletedOrder',order); },
    setPaymentType(context,paymentType){ context.commit('setPaymentType',paymentType); },
    setSelectedOrderProduct(context,selectedOrderProduct){ context.commit('setSelectedOrderProduct',selectedOrderProduct); },
    setSelectedOrder(context,selectedOrder){ context.commit('setSelectedOrder',selectedOrder); },
    setAcconto(context,acconto){ context.commit('setAcconto',acconto); },
    setOrderNote(context,orderNote){ context.commit('setOrderNote',orderNote); },
    setTimeRange(context,timeRange){ context.commit('setTimeRange',timeRange); },
    setOrderNoteToAdd(context,orderNoteToAdd){ context.commit('setOrderNoteToAdd',orderNoteToAdd); },
    setShowReceipt(context,showReceipt){ context.commit('setShowReceipt',showReceipt); },
    refreshRightContainerContentComponentKey(context){ context.commit('refreshRightContainerContentComponentKey'); },
    refreshRightContainerFooterComponentKey(context){ context.commit('refreshRightContainerFooterComponentKey'); },
    setRegistrationType(context,registrationType){ context.commit('setRegistrationType',registrationType); },
    setAddressToAdd(context,addressToAdd){ context.commit('setAddressToAdd',addressToAdd); },
    addAddressToSelectedCustomer(context,addressToAdd){ context.commit('addAddressToSelectedCustomer',addressToAdd); },
    setCustomerToAdd(context,customerToAdd){ context.commit('setCustomerToAdd',customerToAdd); },
    setEmptyCustomerToAdd(context){ context.commit('setEmptyCustomerToAdd'); },
    setCustomerAddressToAdd(context,customerToAdd){ context.commit('setCustomerAddressToAdd',customerToAdd); },
    setEmptyAddressToAdd(context){ context.commit('setEmptyAddressToAdd'); },
    setSelectedAddress(context,selectedAddress){ context.commit('setSelectedAddress',selectedAddress); },
    setSliderMinMax(context,sliderMinMax){ context.commit('setSliderMinMax',sliderMinMax); },
    setSliderValue(context,sliderValue){ context.commit('setSliderValue',sliderValue); },
    setSelectedCustomer(context,selectedCustomer){ context.commit('setSelectedCustomer',selectedCustomer); },
    setSelectedShipping(context,selectedShipping){ context.commit('setSelectedShipping',selectedShipping); },
    setCustomerSearchWord(context,customerSearchWord){ context.commit('setCustomerSearchWord',customerSearchWord); },
    setCart(context,cart){ context.commit('setCart',cart); },
    setPage(context,page){ context.commit('setPage',page); },
    setCartCoupon(context,cartCoupon){ context.commit('setCartCoupon',cartCoupon); },
    setCouponToAdd(context,couponToAdd){ context.commit('setCouponToAdd',couponToAdd); },
    addCouponToList(context,coupon){ context.commit('addCouponToList',coupon); },
    setShowMenu(context,showMenu){ context.commit('setShowMenu',showMenu); },
    setIsLoadingCustomers(context,isLoadingCustomers){ context.commit('setIsLoadingCustomers',isLoadingCustomers); },
    setIsLoadingCustomer(context,isLoadingCustomer){ context.commit('setIsLoadingCustomer',isLoadingCustomer); },
    setIsLoadingCoupon(context,isLoadingCoupon){ context.commit('setIsLoadingCoupon',isLoadingCoupon); },
    setCustomersList(context,customersList){ context.commit('setCustomersList',customersList); },
    setRightNavigation(context,rightNavigation){ context.commit('setRightNavigation',rightNavigation); },
    setSelectedProduct(context,selectedProduct){ context.commit('setSelectedProduct',selectedProduct); },
    setShowProductModal(context,showProductModal){ context.commit('setShowProductModal',showProductModal); },
    setShowEtichetteModal(context,showEtichetteModal){ context.commit('setShowEtichetteModal',showEtichetteModal); },
    setShowProductModalWithDesc(context,showProductModalWithDesc){ context.commit('setShowProductModalWithDesc',showProductModalWithDesc); },
    setShowProductModalFromCart(context,showProductModalFromCart){ context.commit('setShowProductModalFromCart',showProductModalFromCart); },
    setSearchWord(context,searchWord){ context.commit('setSearchWord',searchWord); },
    setActiveFilters(context,activeFilters){ context.commit('setActiveFilters',activeFilters); },
    addActiveFilter(context,activeFilter){ context.commit('addActiveFilter',activeFilter); },
    addToCart(context,product){ context.commit('addToCart',product); },
    addDiscountToProduct(context,discount){ context.commit('addDiscountToProduct',discount); },
    addHoldCarts(context,cart){ context.commit('addHoldCarts',cart); },
    removeFromHoldCarts(context,cartIndex){ context.commit('removeFromHoldCarts',cartIndex); },
    removeFromCart(context,product){ context.commit('removeFromCart',product); },
    removeActiveFilter(context,activeFilter){ context.commit('removeActiveFilter',activeFilter); },
    setLastUpdate(context,lastUpdate){ context.commit('setLastUpdate',lastUpdate); },
    setMissingInfo(context,missingInfo){ context.commit('setMissingInfo',missingInfo); },
    setMissingInfoByType(context,missingInfo){ context.commit('setMissingInfoByType',missingInfo); },
    setDataFromResponse(context,data){
      if(data){
        // console.log(context.getters.getLastUpdate + " " + data.header.originated_at + " " + data.data.length)
        // console.log(context.getters.getLastUpdate < data.header.originated_at)
        if(context.getters.getLastUpdate<data.header.originated_at){
          context.commit('setLastUpdate',data.header.originated_at);
          if(!data.isPageChanged){
            context.commit('setProducts',data.data);
            context.commit('setResultsNumber',data.header.results_count);
            context.commit('setFilterCategories',data.stats.category);
            if(Static.checkKey(context.getters.getActiveFilters,{key:'selling_price'})===-1) {
              context.commit('setFilterPrices',data.stats.selling_price);
              context.commit('setSliderMinMax',[Math.floor(data.stats.selling_price.min),Math.ceil(data.stats.selling_price.max)]);
              context.commit('setSliderValue',[Math.floor(data.stats.selling_price.min),Math.ceil(data.stats.selling_price.max)]);
            }
            context.commit('setFilterManufacturers',data.stats.manufacturer);
            context.commit('setPage',1);
          } else {
            var dataToAdd=[];
            context.getters.getProducts.forEach(item=>{ dataToAdd.push(item) });
            data.data.forEach(item=>{ dataToAdd.push(item) });
            context.commit('setProducts',dataToAdd);
          }
        }
      } else {
        context.commit('setLastUpdate',Date.now());
        context.commit('setProducts',{});
        context.commit('setResultsNumber',0);
        context.commit('setFilterCategories',{});
        context.commit('setFilterPrices',{});
        context.commit('setFilterManufacturers',{});
        context.commit('setPage',1);
      }
    }
  },
  modules:{rest,idb}
})
