<template>
  <div class="tab" :class="[name,{selected:getRightNavigation==name}]" v-on:click="setRightNavigation(name)">
    <div class="container">
      <div class="name"> {{name}} </div>
      <div class="counter" :class="{'active':counter>0}"> {{counter}} </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import Static from '@/utils/Static';

export default {
  name: 'tab',
  props: {
    name: String
  },computed:{
      ...mapGetters(['getFilterCounter','getCartCounter','getRightNavigation']),
      counter(){
          return this.name==Static.CART?this.getCartCounter:this.getFilterCounter
      },
  },methods:{
    ...mapActions(['setRightNavigation'])
  }
}
</script>

<style src="./Tab.css" scoped/>
