<template>
  <div class="navigation">
      <LeftContainer/>
      <RightContainer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LeftContainer from './leftContainer/LeftContainer';
import RightContainer from './rightContainer/RightContainer';

export default {
  name: 'navigation',
  components:{
    LeftContainer,RightContainer
  },
  methods:{
    ...mapGetters(['setNavigation']),
  }
}
</script>

<style src="./Navigation.css" scoped></style>