<template>
  <div class="receipt" :class="{'shown':getShowReceipt}" @click="setShowReceipt(false)">
    <div class="modal-container" @click.stop="">
      <div class="close-icon" @click="setShowReceipt(false)"></div>
      <div id="receiptToPrint">
        <div class="receipt-container">
          <div class="logo"></div>
          <div> ORDINE: {{getDataToPrint?getDataToPrint.id:''}} </div>
          <div> DATA: {{getDataToPrint?getOrderDate:''}} </div>
          <div> NEGOZIO: {{getUser?getUser.store:''}} </div>
          <div> CLIENTE: {{getDataToPrint?getDataToPrint.customerName:''}} </div>
          <VueBarcode v-if="!isInStore" class="ean" :value="getDataToPrint.id?'@'+getDataToPrint.id:''" :displayValue="false"/>
          <div class="row">
            <div class="column">Prodotto</div>
            <div class="column">Qtà</div>
            <div class="column">TP</div>
            <div class="column">Prezzo</div>
          </div>
          <div v-for="(item,index) in getDataToPrint?getDataToPrint.products:[]" :key="'receipt_item_'+index">
            <div class="row">
              <div class="column">
                <div>{{item.name}}</div>
                <div>{{item.sku}}</div>
              </div>
              <div class="column">{{item.qty}}</div>
              <div class="column">{{item.eta}}</div>
              <div class="column">{{item.total + "€"}}</div>
            </div>
            <div class="row">
              <VueBarcode class="ean" :value="item.ean?item.ean:''" :displayValue="true"/>
              <VueBarcode class="ean" :value="item.ean? '#;'+getDataToPrint.id+';'+item.sku+';':''" :displayValue="true" v-if="isInStore"/>
            </div>
          </div>
          <div class="row separator"> </div>
          <div class="row"> La data stimata di spedizione ordine è <span>{{ " " +(getDataToPrint?getDataToPrint.lastDate:"") }}</span> </div>
          <div class="row separator"> </div>
          <div class="row total" v-if="getSconto>0">
            <div class="column">Sconto</div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column">{{getDataToPrint?"-"+getSconto+"€":""}}</div>
          </div>
          <div class="row total">
            <div class="column">Spedizione</div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column">{{getDataToPrint?getDataToPrint.shippingCost.toFixed(2)+"€":""}}</div>
          </div>
          <div class="row total">
            <div class="column">Totale</div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column">{{getDataToPrint?getDataToPrint.total.toFixed(2)+"€":""}}</div>
          </div>
          <div class="row total">
            <div class="column">Acconto</div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column">{{getDataToPrint?getDataToPrint.totalpaid.toFixed(2)+"€":""}}</div>
          </div>
          <div class="row total">
            <div class="column">Totale da pagare</div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column">{{getDataToPrint?getDataToPrint.totaltopay.toFixed(2) +"€":""}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Static from '../../utils/Static'

export default {
  name: 'Receipt',
  computed:{
    ...mapGetters(['getShowReceipt','getUser','getCart','getRegistrationType','getDataToPrint']),
    getOrderDate(){ return Static.getLocalDate(this.getDataToPrint.date).format('DD/MM/YYYY HH:mm'); },
    getSconto(){ return (Static.getReceiptTotal(this.getDataToPrint.products) - this.getDataToPrint.total).toFixed(2); },
    isInStore(){
      return this.getUser.address.streetname==this.getDataToPrint.shippingAddress.streetname &&
        this.getUser.address.postcode==this.getDataToPrint.shippingAddress.postcode &&
        this.getUser.address.city==this.getDataToPrint.shippingAddress.city &&
        this.getUser.address.firstname==this.getDataToPrint.shippingAddress.firstname &&
        this.getUser.address.lastname==this.getDataToPrint.shippingAddress.lastname
    },
  },
  methods:{
    ...mapActions(['setShowReceipt'])
  }
}
</script>

<style src="./Receipt.css" scoped/>
